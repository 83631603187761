// Booking Engine Alert reducer

import { initializeState } from '../../../utilities/storageUtils';
import bookingEngineAlertActionTypes from './bookingEngineAlertEntity.types';

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  hasError: false,
  errorMessage: ''
};

const bookingEngineAlertReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case bookingEngineAlertActionTypes.SET_BOOK_ENGINE_ALERT_IS_SUCCESSFUL:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        hasError: false,
        errorMessage: ''
      };

    case bookingEngineAlertActionTypes.SET_BOOK_ENGINE_ALERT_IS_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: ''
      };

    case bookingEngineAlertActionTypes.SET_BOOK_ENGINE_ALERT_GOT_ERROR:
      return {
        ...state,
        data: [],
        isLoading: true,
        hasError: true,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
};

export default bookingEngineAlertReducer;
