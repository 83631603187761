import { colors } from '../components/ui-kit/themes/theme';
export const getThemeColor = theme => {
  switch (theme) {
    case 'Red':
      return colors.digitalBrightRed[100];
    case 'Blue':
      return colors.kiddiePoolBlue[100];
    case 'Gold':
      return colors.deepLakeBlue[100];
    default:
      return colors.deepLakeBlue[20];
  }
};

export const parsePassHTMLTextByTag = (text, tag) => {
  return text
    .split(`<${tag}>`)
    .filter(item => item.includes(`</${tag}>`))
    .map(item => item.split(`</${tag}>`)[0]);
};

export const isBestValue = bestValueStr =>
  bestValueStr === 'true' ? true : false;

export const isValidPrice = value => {
  return typeof value === 'number' && !Number.isNaN(value);
};

export const getFormattedPrice = value => {
  if (isValidPrice(value)) {
    return value.toFixed(2);
  }

  return '0.00';
};
