// THIS FILE WILL RUN ANY CONFIGURATION THAT WILL LOAD BEFORE RENDER APP
import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import * as LodgeConfigActions from '../../store/componentStores/LodgeConfig/lodgeConfig.actions';
import { getReferralEnabledLocation } from '../../store/componentStores/LodgeConfig/lodgeConfig.selectors';
import * as LodgeLocationsActions from '../../store/componentStores/LodgeLocations/lodgeLocations.actions';
import { getLocationList } from '../../store/componentStores/LodgeLocations/lodgeLocations.selectors';

import * as currentLodgeActionCreators from '../../store/componentStores/CurrentLodge/currentLodge.actions';
import { getResortLocation } from '../../store/componentStores/CurrentLodge/currentLodge.selectors';

import { getGeolocationData } from '../../store/componentStores/Geolocation/geolocation.actions';
import {
  GEO_DATA_TTL,
  LODGE_CONFIG_DATA_TTL,
  LODGE_LOCATION_DATA_TTL
} from '../../store/componentStores/utilities/endpointTtlValidation';
import { setLocation } from '../../utilities/analytics';
import { SITE_NIAGON } from '../../utilities/constants';
import duettoTracking from '../../utilities/duetto';
import {
  LocalStorageKeys,
  isReferAfriendSessionCreatedAlready,
  sessionStorage_transfer
} from '../../utilities/storageUtils';
import useEndpointValidation from '../utilities/hooks/useEndpointValidation';

const AppConfiguration = ({
  children,
  getLoyaltyConfigByResort,
  getPlanConfigByResort,
  getGeolocationData,
  getLodgeLocations,
  isInPrimerState,
  isLoyaltyEnabled,
  isReferralEnabledLocation,
  getCreditPackageConfigByResort,
  getPaymentConfigByResort,
  getLeadGenConfigByResort,
  getCmpConfigByResort,
  resetLodgeConfig,
  resortLocation,
  locationList,
  updateCurrentLodge,
  getCartConfigByResort,
  getBookingWidgetConfigByResort
}) => {
  const endpointList = [
    {
      name: 'geolocation',
      TTL: GEO_DATA_TTL,
      call: getGeolocationData,
      isLodgeSpecific: false
    },
    {
      name: 'lodgeLocations',
      TTL: LODGE_LOCATION_DATA_TTL,
      call: getLodgeLocations,
      isLodgeSpecific: false
    },
    {
      name: 'loyaltyConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getLoyaltyConfigByResort,
      isLodgeSpecific: true
    },
    {
      name: 'planConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getPlanConfigByResort,
      validateUrl: true,
      isLodgeSpecific: true
    },
    {
      name: 'creditPackageConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getCreditPackageConfigByResort,
      validateUrl: true,
      isLodgeSpecific: true
    },
    {
      name: 'leadGenConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getLeadGenConfigByResort,
      isLodgeSpecific: true
    },
    {
      name: 'cmpConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getCmpConfigByResort,
      validateUrl: true,
      isLodgeSpecific: true
    },
    {
      name: 'paymentConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getPaymentConfigByResort,
      validateUrl: true,
      isLodgeSpecific: true
    },
    {
      name: 'cartConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getCartConfigByResort,
      isLodgeSpecific: true
    },
    {
      name: 'bookingWidgetConfigByResort',
      TTL: LODGE_CONFIG_DATA_TTL,
      call: getBookingWidgetConfigByResort,
      isLodgeSpecific: true
    }
  ];

  useEndpointValidation(endpointList, resetLodgeConfig, resortLocation);

  useEffect(() => {
    if (!isReferralEnabledLocation) return;
    // Ask other tabs for session storage
    if (!isReferAfriendSessionCreatedAlready()) {
      localStorage.setItem(LocalStorageKeys.GET_SESSION_STORAGE, Date.now());
    }

    // Run transfer
    window.addEventListener('storage', sessionStorage_transfer);

    // Clean events
    return function cleanupListener() {
      window.removeEventListener('storage', sessionStorage_transfer, false);
    };
  }, [isReferralEnabledLocation]);

  useEffect(() => {
    const voyagersClubPhase = !isLoyaltyEnabled ? 'VO' : isInPrimerState ? 'V1' : 'V2';
    ReactGA.set({ dimension58: voyagersClubPhase });
  }, [isInPrimerState, isLoyaltyEnabled]);

  useEffect(() => {
    if (resortLocation) {
      duettoTracking.initialize(
        resortLocation === SITE_NIAGON
          ? process.env.REACT_APP_DUETTO_ID_NIAGARA
          : process.env.REACT_APP_DUETTO_ID_UNIVERAL
      );
      setLocation(resortLocation);
    }
  }, [resortLocation]);

  useEffect(() => {
    updateCurrentLodge(locationList);
  }, [locationList, updateCurrentLodge]);

  return resortLocation ? <Fragment>{children}</Fragment> : null;
};

const mapStateToProps = state => ({
  isInPrimerState: state.lodgeConfig.isInPrimerState,
  isLoyaltyEnabled: state.lodgeConfig.isLoyaltyEnabled,
  isReferralEnabledLocation: getReferralEnabledLocation(state.lodgeConfig),
  resortLocation: getResortLocation(state.currentLodge),
  locationList: getLocationList(state.lodgeLocations)
});

const mapDispatchToProps = {
  getLoyaltyConfigByResort: LodgeConfigActions.getLoyaltyConfigByResort,
  getPlanConfigByResort: LodgeConfigActions.getPlanConfigByResort,
  getCreditPackageConfigByResort: LodgeConfigActions.getCreditPackageConfigByResort,
  getLodgeLocations: LodgeLocationsActions.getAllLodgesLocation,
  getPaymentConfigByResort: LodgeConfigActions.getPaymentConfigByResort,
  getLeadGenConfigByResort: LodgeConfigActions.getLeadGenConfigByResort,
  getCmpConfigByResort: LodgeConfigActions.getCmpConfigByResort,
  resetLodgeConfig: LodgeConfigActions.resetLodgeConfig,
  updateCurrentLodge: currentLodgeActionCreators.updateCurrentLodge,
  getCartConfigByResort: LodgeConfigActions.getCartConfigByResort,
  getBookingWidgetConfigByResort: LodgeConfigActions.getBookingWidgetConfigByResort,
  getGeolocationData: getGeolocationData
};

export default connect(mapStateToProps, mapDispatchToProps)(AppConfiguration);
