import { include } from 'named-urls';

const routes = {
  blank: '/',
  home: '/home',
  planSearchForm: include('/(waterpark-attractions|suites|dining-shopping|dates|deals)', {
    t2: '',
    t3: '/*'
  }),
  plan: include('/plan', {
    self: '',
    activities: 'packages-activities',
    dining: 'packages-dining',
    payment: 'payment',
    paymentSuccess: 'confirmation'
  }),
  birthdayParties: include('/birthday-parties', {
    // self: '', birthday todo: placeholder for t2 page (should be finalized or removed once birthday goes live, end of 2024)
    partySelect: 'booking',
    addons: 'packages-addons',
    guestInfo: 'guest-info ',
    payment: 'payment',
    paymentSuccess: 'confirmation'
  }),
  daypass: include('/daypass', {
    self: '',
    payment: 'payment',
    booking: 'booking',
    paymentSuccess: 'confirmation'
  }),
  eventCalendar: '/waterpark-attractions/calendar/:date?',
  forgotPassword: '/forgotpassword',
  changePassword: '/changepassword',
  unlockAccount: '/unlockaccount',
  myRecentSearches: '/my-recent-searches',
  profile: include('/user/profile', {
    self: '',
    myPreferences: 'my-preferences'
  }),
  reservations: include('/reservations', {
    self: '',
    myReservations: include('my-reservations', {
      self: '',
      detail: include(':reservationId', {
        self: '',
        addPackage: 'add-a-package',
        packagemodal: '(cabanas|passes|birthday-packages|dining|lco)'
      })
    }),
    search: 'search/'
  }),
  reservation: include('/reservation', {
    self: '',
    detail: include(':reservationId', {
      self: '',
      checkIn: 'check-in',
      folio: 'folio'
    })
  }),
  birthdays: '*/:type(birthdays)/',
  customerService: include('/customer-service', {
    self: '',
    contactUs: 'contact-us'
  }),
  leadGenSignUp: '/leadgen-signup',
  loyaltyLandingPageForm: '/voyagers-club',
  promoSignUp: '/promo-sign-up',
  referAFriend: '/user/refer-a-friend',
  referAFriendLoggedOut: '/refer-a-friend',
  venues: include('/venues', {
    self: '',
    planner: 'event-planners'
  })
};

export default routes;
