// Guests selectors

/**
 * Get the selected adults counts.
 * @param {Object} state
 * @returns {number} Number of adults.
 */
export const getAdultsCount = state => state.adultsCount;

/**
 * Get the selected kids counts.
 * @param {Object} state
 * @returns {number} Number of adults.
 */
export const getKidsCount = state => state.kidsCount;

/**
 * Get the kids ages.
 * @param {Object} state
 * @returns {Array} Array of number.
 */
export const getKidsAges = state => state.kidsAges;

/**
 * Get the number of guests (adults + kids).
 * @param {Object} state
 * @returns {number} Number of guests.
 */
export const getNumberOfGuests = state => state.adultsCount + state.kidsCount;
