// Offer action types

const offerActionTypes = {
  ADD_OFFER_CODE: 'ADD_OFFER_CODE',
  REMOVE_OFFER_CODE: 'REMOVE_OFFER_CODE',
  SET_OFFER_CODE_DESCRIPTION: 'SET_OFFER_CODE_DESCRIPTION',
  CREATE_OFFER_ERROR: 'CREATE_OFFER_ERROR',
  ADD_OFFER_ACTIVE_TYPE: 'ADD_OFFER_ACTIVE_TYPE',
  ADD_OFFER_EMAIL_ACTIVE_TYPE: 'ADD_OFFER_EMAIL_ACTIVE_TYPE',
  REMOVE_OFFER_ACTIVE_TYPE: 'REMOVE_OFFER_ACTIVE_TYPE',
  REMOVE_OFFERS_ALL_ACTIVE_TYPE: 'REMOVE_ALL_ACTIVE_OFFERS_TYPE__FIX_DUPLICATE'
};

export default offerActionTypes;
