// DayPasses reducer

import { initializeState } from '../../../utilities/storageUtils';
import dayPassActionTypes from './dayPasses.types';

const INITIAL_STATE = {
  numberOfGuests: 0,
  arrivalDate: null,
  selectedTitle: '',
  selectedRate: 0,
  selectedTaxesAndFees: 0,
  selectedRateCode: '',
  selectedItemCode: '',
  selectedDescription: '',
  promoCode: '',
  qualifyingId: '',
  isLoading: false, //remove on next realease
  isDayPassAvailable: false, //remove on next realease
  item: {
    rate: '',
    itemCode: '',
    rateCode: '',
    baseRate: 0
  },
  availabilityData: [],
  promoCodeData: null,
  isDaypassFetching: false,
  daypassCalendarMonth: null,
  parkingFee: 0,
  resortFee: 0,
  sustainabilityFee: 0,
  taxesAndFees: 0
};

const dayPassesReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case dayPassActionTypes.UPDATE_DAYPASSES_LOADING:
      return {
        ...state,
        item: {
          ...state.item
        },
        isLoading: action.isLoading
      };

    case dayPassActionTypes.IS_DAYPASSES_AVAILABLE:
      return {
        ...state,
        item: {
          ...state.item
        },
        isDayPassAvailable: action.isDayPassAvailable
      };

    case dayPassActionTypes.UPDATE_DAYPASSES_METADATA:
      return {
        ...state,
        item: {
          ...action.item
        }
      };

    case dayPassActionTypes.SET_DAYPASSES:
      return {
        ...state,
        arrivalDate: action.arrivalDate,
        numberOfGuests: action.numberOfGuests,
        selectedTitle: action.selectedTitle,
        selectedRate: action.selectedRate,
        selectedTaxesAndFees: action.selectedTaxesAndFees,
        selectedItemCode: action.selectedItemCode,
        selectedRateCode: action.selectedRateCode,
        promoCode: action.promoCode,
        qualifyingId: action.qualifyingId,
        selectedDescription: action.selectedDescription,
        parkingFee: action.parkingFee,
        resortFee: action.resortFee,
        sustainabilityFee: action.sustainabilityFee,
        taxesAndFees: action.totalTaxesAndFees,
        item: [action.item]
      };

    case dayPassActionTypes.SET_DAYPASSES_AVAILABLE_DATES:
      // We get the month of the request if the request has days
      const date =
        action.availabilityData.dayPasses?.length > 0 ? new Date(action.availabilityData.dayPasses[0].date) : null;
      const monthFromAPI = date ? date.getMonth() + 1 : null;
      const isLastInfo = monthFromAPI === action.date.month && date.getFullYear() === action.date.year;

      return {
        ...state,
        promoCodeData: action.availabilityData.promotion ? action.availabilityData.promotion : '',
        ...(isLastInfo && {
          availabilityData: action.availabilityData.dayPasses ? action.availabilityData.dayPasses : []
        })
      };
    case dayPassActionTypes.UPDATE_DAYPASSES_FETCHING:
      return {
        ...state,
        isDaypassFetching: action.fetchData.isDaypassFetching,
        daypassCalendarMonth: action.fetchData.isDaypassFetching ? action.fetchData.monthToFetch : null,
        ...(action.fetchData.isDaypassFetching && { availabilityData: [] })
      };

    default:
  }

  return state;
};

export default dayPassesReducer;
