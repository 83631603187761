//Account reducer

import { initializeState } from '../../../utilities/storageUtils';
import accountActionTypes from './account.types';

const INITIAL_STATE = {
  forgotPwdReqIsLoading: false,
  forgotPwdReqHasFinished: false,
  changePwdReqIsLoading: false,
  changePwdReqHasFailed: false,
  changePwdReqHasSucceded: false,
  changePwdReqErrorMessage: '',
  userIsLoggedIn: false,
  creatingNewAccount: false,
  newAccountHasSucceded: false,
  newAccountValidationErrors: null,
  newAccountErrorMessage: '',
  accountModalIsOpen: false,
  loginErrorMessage: '',
  loginHasFailed: false,
  currentLoggedInUser: null,
  unlockAccountReqIsLoading: false,
  unlockAccountReqHasFailed: false,
  unlockAccountReqHasSucceded: false,
  unlockAccountReqErrorMessage: ''
};

const accountReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  // Account information reducers
  switch (action.type) {
    case accountActionTypes.POST_ACCOUNT_FORGOT_PWD_INFO:
      return {
        ...state,
        forgotPwdReqIsLoading: true,
        forgotPwdReqHasFinished: false,
        forgotPwdError: false
      };
    // The request is currently running
    case accountActionTypes.POST_ACCOUNT_FORGOT_PWD_INFO_SUCCESS:
      return {
        ...state,
        forgotPwdReqIsLoading: false,
        forgotPwdReqHasFinished: true,
        forgotPwdError: false
      };
    case accountActionTypes.POST_ACCOUNT_FORGOT_PWD_INFO_FAILURE:
      return {
        ...state,
        forgotPwdReqIsLoading: false,
        forgotPwdReqHasFinished: false,
        forgotPwdError: action.error
      };
    // CHANGE PASSWORD
    // Post to change password request succeded
    case accountActionTypes.POST_ACCOUNT_CHANGE_PWD_INFO_SUCCESS:
      return {
        ...state,
        changePwdReqIsLoading: false,
        changePwdReqHasFailed: false,
        changePwdReqHasSucceded: true,
        forgotPwdErrorMessage: ''
      };
    // The request is currently running
    case accountActionTypes.POST_ACCOUNT_CHANGE_PWD_INFO_LOADING:
      return {
        ...state,
        changePwdReqIsLoading: action.changePwdReqIsLoading
      };
    // Post to change password request failed
    case accountActionTypes.POST_ACCOUNT_CHANGE_PWD_INFO_ERROR:
      return {
        ...state,
        changePwdReqIsLoading: false,
        changePwdReqHasFailed: true,
        changePwdReqHasSucceded: false,
        changePwdReqErrorMessage: action.changePwdReqErrorMessage
      };
    // Set if user is already logged in
    case accountActionTypes.CURRENT_ACCOUNT_USER_IS_LOGGED_IN:
      return {
        ...state,
        userIsLoggedIn: action.userIsLoggedIn
      };

    case accountActionTypes.CURRENT_ACCOUNT_LOGGED_IN_USER:
      return {
        ...state,
        currentLoggedInUser: action.currentLoggedInUser
      };

    // A new account is being created
    case accountActionTypes.POST_ACCOUNT_NEW_LOADING:
      return {
        ...state,
        creatingNewAccount: action.creatingNewAccount
      };
    // The new account request has successfully finished
    case accountActionTypes.POST_ACCOUNT_NEW_SUCCESS:
      return {
        ...state,
        newAccountHasSucceded: true,
        creatingNewAccount: false,
        newAccountValidationErrors: null,
        newAccountErrorMessage: ''
      };
    // The new account request has successfully finished
    case accountActionTypes.POST_ACCOUNT_NEW_ERROR:
      return {
        ...state,
        newAccountHasSucceded: false,
        creatingNewAccount: false,
        newAccountValidationErrors: action.newAccountValidationErrors,
        newAccountErrorMessage: action.newAccountErrorMessage
      };
    // Reset all values to their initial state
    case accountActionTypes.RESET_ACCOUNT_NEW_TO_INITIAL_STATE:
      return {
        ...state,
        creatingNewAccount: false,
        newAccountHasSucceded: false,
        newAccountValidationErrors: null,
        newAccountErrorMessage: ''
      };
    // Set an login error
    case accountActionTypes.LOGIN_ACCOUNT_ERROR_TYPE:
      return {
        ...state,
        loginErrorMessage: action.loginErrorMessage,
        loginHasFailed: true
      };

    // post to unlock account request is succeded.
    case accountActionTypes.POST_ACCOUNT_UNLOCK_SUCCESS:
      return {
        ...state,
        unlockAccountReqIsLoading: false,
        unlockAccountReqHasFailed: false,
        unlockAccountReqHasSucceded: true,
        unLockAccountErrorMessage: ''
      };

    // The request is currently running
    case accountActionTypes.POST_ACCOUNT_UNLOCK_LOADING:
      return {
        ...state,
        unlockAccountReqIsLoading: action.unlockAccountReqIsLoading
      };

    // Post to unlock account request failed
    case accountActionTypes.POST_ACCOUNT_UNLOCK_ERROR:
      return {
        ...state,
        unlockAccountReqIsLoading: false,
        unlockAccountReqHasFailed: true,
        unlockAccountReqHasSucceded: false,
        unlockAccountReqErrorMessage: action.unlockAccountReqErrorMessage
      };
    //Reset pw feedback
    case accountActionTypes.RESET_ACCOUNT_PW_FEEDBACK:
      return {
        ...state,
        changePwdReqHasFailed: false,
        changePwdReqHasSucceded: false
      };
    //Update Loyalty Flagh
    case accountActionTypes.LOYALTY_ACCOUNT_OPT_IN:
      return {
        ...state,
        currentLoggedInUser: { ...state.currentLoggedInUser, loyaltyOptIn: action.loyaltyOptIn }
      };
    //Update currentLoggedInUser with the memberId and referralCode
    case accountActionTypes.UPDATE_ACCOUNT_MEMBER_ID_AND_REFERRAL_CODE:
      return {
        ...state,
        currentLoggedInUser: {
          ...state.currentLoggedInUser,
          loyaltyMemberId: action.loyaltyMemberId,
          loyaltyReferralCode: action.loyaltyReferralCode
        }
      };

    // Reset all account methods
    case accountActionTypes.RESET_ACCOUNT_METHODS:
      return {
        forgotPwdReqIsLoading: false,
        forgotPwdReqHasFinished: false,
        changePwdReqIsLoading: false,
        changePwdReqHasFailed: false,
        changePwdReqHasSucceded: false,
        changePwdReqErrorMessage: '',
        creatingNewAccount: false,
        newAccountHasSucceded: false,
        newAccountValidationErrors: null,
        newAccountErrorMessage: '',
        loginErrorMessage: '',
        loginHasFailed: false,
        unlockAccountReqIsLoading: false,
        unlockAccountReqHasFailed: false,
        unlockAccountReqHasSucceded: false,
        unlockAccountReqErrorMessage: ''
      };
    default:
      return state;
  }
};

export default accountReducer;
