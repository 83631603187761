// Profile reducer

import profileActionTypes from './profile.types';

export const INITIAL_STATE = {
  isLoading: false,
  isUpdateSuccess: false,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    street: '',
    city: '',
    stateProvince: '',
    country: '',
    zip: '',
    phoneNumber: '',
    streetLine1: '',
    streetLine2: '',
    birthdate: '',
    password: {
      currentPassword: '',
      newPassword: ''
    },
    family: [],
    isSocialAccountLogin: false,
    isConnectedToFacebook: false,
    isConnectedToGoogle: false
  }
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case profileActionTypes.IS_PROFILE_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        isUpdated: action.isUpdateSuccess
      };
    case profileActionTypes.GET_PROFILE_USER:
      return {
        ...state,
        isLoading: action.isLoading,
        isUpdated: false
      };
    case profileActionTypes.GET_PROFILE_USER_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        user: action.user,
        isUpdated: false
      };
    case profileActionTypes.GET_PROFILE_USER_ERROR:
      return {
        ...state,
        isLoading: action.isLoading,
        user: action.user,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default profileReducer;
