// Offer Actions

import offerActionTypes from './offer.types';
import { getNormalizedCode } from '../../../utilities/availabilityUtils';
import AvailabilityApiClient from '../../../api/clients/AvailabilityApiClient';
import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';
import { DATE_FORMATS } from '../../../utilities/constants';
import { getErrorMessage } from '../../../utilities/messageUtils';

const ERROR_MESSAGE = 'Unfortunately that is not a valid offer code. Please re-enter or view our other offers.';

export const addOfferCode = code => ({
  type: offerActionTypes.ADD_OFFER_CODE,
  code: getNormalizedCode(code)
});

export const removeOfferCode = () => ({ type: offerActionTypes.REMOVE_OFFER_CODE });
export const getOfferCodeDescription = (location, offerCode, arrivalDate = null, departureDate = null) => dispatch => {
  // Call availability V2.1 to get the offer code description by the stay dates
  let apiClient = new AvailabilityApiClient('v2.1');

  const request = apiClient.getOfferCodeDescription({
    location,
    offerCode: getNormalizedCode(offerCode),
    arrival: GwDatesWrapper.format(arrivalDate, DATE_FORMATS.default),
    departure: GwDatesWrapper.format(departureDate, DATE_FORMATS.default)
  });

  return request.then(
    response => {
      if (response.data) {
        dispatch({
          type: offerActionTypes.SET_OFFER_CODE_DESCRIPTION,
          description: response.data
        });
      } else {
        dispatch({
          type: offerActionTypes.CREATE_OFFER_ERROR,
          errorMessage: ERROR_MESSAGE
        });
      }
    },
    err => {
      dispatch({
        type: offerActionTypes.CREATE_OFFER_ERROR,
        errorMessage: getErrorMessage(err.message)
      });
    }
  );
};
export const setOfferCodeDescription = description => dispatch => {
  dispatch({
    type: offerActionTypes.SET_OFFER_CODE_DESCRIPTION,
    description: description
  });
};
export const addActiveOffer = offerData => dispatch => {
  dispatch({
    type: offerActionTypes.ADD_OFFER_ACTIVE_TYPE,
    offerData: offerData
  });
};
export const addEmailActiveOffer = offerData => dispatch => {
  dispatch({
    type: offerActionTypes.ADD_OFFER_EMAIL_ACTIVE_TYPE,
    offerData: offerData
  });
};
export const removeActiveOffer = offerData => dispatch => {
  dispatch({
    type: offerActionTypes.REMOVE_OFFER_ACTIVE_TYPE,
    offerData: offerData
  });
};
export const removeAllActiveOffers = () => dispatch => {
  dispatch({
    type: offerActionTypes.REMOVE_OFFERS_ALL_ACTIVE_TYPE
  });
};
