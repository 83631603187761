//Account Selectors

import get from 'lodash-es/get';

export const getUserIsLoggedIn = state => {
  return state.userIsLoggedIn;
};

export const getUserFirstName = state => {
  return get(state, 'currentLoggedInUser.firstName');
};

export const getUserLastName = state => {
  return get(state, 'currentLoggedInUser.lastName');
};

export const getUserId = state => {
  return get(state, 'currentLoggedInUser.id');
};

export const getChangePwdIsLoading = state => {
  return state.changePwdReqIsLoading;
};

export const getPwChangeSuccess = state => {
  return state.changePwdReqHasSucceded;
};

export const getPwChangeFailed = state => {
  return state.changePwdReqHasFailed;
};

export const getPwErrorMessage = state => {
  return state.changePwdReqErrorMessage;
};

export const getUserLoyaltyOptIn = state => {
  return get(state, 'currentLoggedInUser.loyaltyOptIn');
};

export const getUserLoyaltyPointsBalance = state => {
  return get(state, 'currentLoggedInUser.pointsBalance');
};

export const getUserOffers = state => {
  return get(state, 'currentLoggedInUser.offerList');
};

export const getUserReferralCode = state => {
  return get(state, 'currentLoggedInUser.loyaltyReferralCode');
};

export const getLoyaltyMemberId = state => {
  return get(state, 'currentLoggedInUser.loyaltyMemberId');
};

export const getReferralNotEligible = state => {
  return get(state, 'currentLoggedInUser.referralNotEligible');
};

export const shouldUseV22CreateUserAPI = state => {
  const isLoyaltyEnabled = state.lodgeConfig.isLoyaltyEnabled;
  const isOnLLP = state.router.location.pathname === '/voyagers-club';
  const isPrimerState = state.lodgeConfig.isInPrimerState;

  if (isLoyaltyEnabled === false) return false; // use V2.1 Api if loyalty is not active

  if (isLoyaltyEnabled === true && isOnLLP === false && isPrimerState === true) return false; // use V2.1 Api if user is on Booking Engine and lodge is on Primer State

  return true; // use V2.2 Api
};

export const getExpirationPoints = state => {
  const nextExpirationDate = get(state, 'currentLoggedInUser.nextExpirationDate');
  const expiringMetricValue = get(state, 'currentLoggedInUser.expiringMetricValue');
  const expirationObject = { nextExpirationDate, expiringMetricValue };
  return expirationObject;
};
