// Global Modal reducer

import globalModalActionTypes from './globalModal.types';

const INITIAL_STATE = {
  isVisible: false,
  keyContentComponent: undefined,
  shouldUseGWComponent: false,
  params: {}
};

const globalModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case globalModalActionTypes.OPEN_GLOBAL_MODAL:
      return {
        isVisible: true,
        keyContentComponent: action.payload.keyContentComponent,
        shouldUseGWComponent: action.payload.shouldUseGWComponent,
        params: action.payload.params
      };
    case globalModalActionTypes.CLOSE_GLOBAL_MODAL:
      return {
        isVisible: false,
        keyContentComponent: undefined,
        shouldUseGWComponent: false,
        params: {}
      };
    case globalModalActionTypes.RESET_GLOBAL_MODAL:
      return {
        isVisible: false,
        keyContentComponent: undefined,
        shouldUseGWComponent: false,
        params: {}
      };
    case globalModalActionTypes.UPDATE_GLOBAL_MODAL_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload.params
        }
      };
    default:
      return state;
  }
};

export default globalModalReducer;
