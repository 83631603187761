// PreCheckIn action types

const preCheckInActionTypes = {
  UPDATE_PRE_CHECK_IN_STEP: 'UPDATE_PRE_CHECK_IN_STEP',
  UPDATE_PRE_CHECK_IN_ADDED_PACKAGES_FAILED: 'UPDATE_PRE_CHECK_IN_ADDED_PACKAGES_FAILED',
  RESET_PRE_CHECK_IN_ADDED_PACKAGES_FAILED: 'RESET_PRE_CHECK_IN_ADDED_PACKAGES_FAILED',
  UPDATE_PRE_CHECK_IN_NEW_PACKAGES: 'UPDATE_PRE_CHECK_IN_NEW_PACKAGES',
  UPDATE_PRE_CHECK_IN_VIRDEE_REDIRECTION_FAILED: 'UPDATE_PRE_CHECK_IN_VIRDEE_REDIRECTION_FAILED'
};

export default preCheckInActionTypes;
