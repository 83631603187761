// Profile action types

const profileActionTypes = {
  IS_PROFILE_LOADING: 'IS_PROFILE_LOADING',
  GET_PROFILE_USER: 'GET_PROFILE_USER',
  GET_PROFILE_USER_SUCCESS: 'GET_PROFILE_USER_SUCCESS',
  GET_PROFILE_USER_ERROR: 'GET_PROFILE_USER_ERROR'
};

export default profileActionTypes;
