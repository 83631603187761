// Dates action types

const datesActionTypes = {
  UPDATE_DATES: 'UPDATE_DATES',
  CLEAR_DATES: 'CLEAR_DATES',
  UPDATE_DATES_SIP: 'UPDATE_DATES_SIP',
  FETCHING_DATES_SIP: 'FETCHING_DATES_SIP',
  UPDATE_DATES_SIP_ERROR: 'UPDATE_DATES_SIP_ERROR'
};

export default datesActionTypes;
