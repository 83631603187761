import { initializeState } from '../../../utilities/storageUtils';
import birthdayPartyActionTypes from './birthdayParty.types';

const INITIAL_STATE = {
  summaryData: {},
  sendingPost: false,
  postFailed: false,
  postErrorMessage: ''
};

const birthdayPartyReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  // Get Book information reducers
  switch (action.type) {
    // Post data request succeded
    case birthdayPartyActionTypes.POST_BIRTHDAY_PARTY_SUCCESS:
      return {
        ...state,
        summaryData: action.summaryData,
        sendingPost: false,
        postFailed: false,
        postErrorMessage: ''
      };
    // The request is currently running
    case birthdayPartyActionTypes.POST_BIRTHDAY_PARTY_LOADING:
      return {
        ...state,
        sendingPost: action.sendingPost
      };
    // The Post request failed
    case birthdayPartyActionTypes.POST_BIRTHDAY_PARTY_ERROR:
      return {
        ...state,
        summaryData: {},
        sendingPost: false,
        postFailed: true,
        postErrorMessage: action.postErrorMessage
      };
    default:
      return state;
  }
};

export default birthdayPartyReducer;
