// Add a Package Actions

import addAPackageActionTypes from './addAPackage.types';

export const addPackage = (packageId, value, options, chargeType) => ({
  type: addAPackageActionTypes.ADD_ADD_A_PACKAGE,
  id: packageId,
  value,
  options,
  chargeType
});
export const removePackage = packageId => ({
  type: addAPackageActionTypes.REMOVE_ADD_A_PACKAGE,
  id: packageId
});
export const addCabana = item => ({
  type: addAPackageActionTypes.SET_ADD_A_PACKAGE_A_CABANA,
  id: `${item.value.rate}~${item.value.packageCode}~${item.value.date}`,
  item: item.value
});
export const removeCabana = item => ({
  type: addAPackageActionTypes.REMOVE_ADD_A_PACKAGE_CABANA,
  id: `${item.value.rate}~${item.value.packageCode}~${item.value.date}`
});
export const removeOtherCabanas = itemsList => ({
  type: addAPackageActionTypes.REMOVE_ADD_A_PACKAGE_OTHER_CABANAS,
  items: itemsList
});
export const clearFlashMessage = () => ({
  type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_FLASH_MESSAGE
});
export const clearPackagesByCodes = packagesCodes => (dispatch, getState) => {
  let currentPackages = getState().addApackage.addedPackages;
  const selectedPackages = {};

  if (Object.keys(currentPackages).length > 0) {
    Object.keys(currentPackages).forEach(function(prop, index) {
      if (!packagesCodes.includes(prop)) selectedPackages[prop] = currentPackages[prop];
    });

    dispatch({
      type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_PACKAGES_BY_CODES,
      addedPackages: selectedPackages
    });
  }
};
export const clearAllAddedPackages = () => ({
  type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES
});
