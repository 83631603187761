// DayPasses selectors

// Selectors
export const getSelectedRate = state => {
  return state.selectedRate;
};

export const getSelectedTitle = state => {
  return state.selectedTitle;
};

export const getPromoCode = state => {
  return state.promoCode;
};

export const getNumberOfGuests = state => {
  return state.numberOfGuests;
};

export const getArrivalDate = state => {
  return state.arrivalDate;
};

export const getDayPassesGrossTotal = state => {
  const { numberOfGuests, selectedRate } = state;
  return selectedRate * numberOfGuests;
};

export const getDayPassesTaxesTotal = state => {
  const { numberOfGuests, selectedTaxesAndFees } = state;
  return selectedTaxesAndFees * numberOfGuests;
};

export const getDayPassesNetTotal = state => {
  const dayPassesGrossTotal = getDayPassesGrossTotal(state);
  const dayPassesTaxesTotal = getTaxesAndFees(state);

  return dayPassesGrossTotal + dayPassesTaxesTotal;
};

export const getSelectedDescription = state => {
  return state.selectedDescription;
};

export const getDayPassParkingFee = state => {
  return state.parkingFee;
};

export const getDayPassResortFee = state => {
  return state.resortFee;
};

export const getDayPassSustainabilityFee = state => {
  return state.sustainabilityFee;
};

export const getTaxesAndFees = state => {
  return state.taxesAndFees;
};
