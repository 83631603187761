// Guests action types

const guestsModalActionTypes = {
  INCREMENT_GUESTS_ADULTS_COUNT: 'INCREMENT_GUESTS_ADULTS_COUNT',
  DECREMENT_GUESTS_ADULTS_COUNT: 'DECREMENT_GUESTS_ADULTS_COUNT',
  SET_GUESTS_ADULTS_COUNT: 'SET_GUESTS_ADULTS_COUNT',
  INCREMENT_GUESTS_KIDS_COUNT: 'INCREMENT_GUESTS_KIDS_COUNT',
  DECREMENT_GUESTS_KIDS_COUNT: 'DECREMENT_GUESTS_KIDS_COUNT',
  SET_GUESTS_KIDS_COUNT: 'SET_GUESTS_KIDS_COUNT',
  SET_GUESTS_KIDS_AGES: 'SET_GUESTS_KIDS_AGES',
  CLEAR_ALL_GUESTS_DATA: 'CLEAR_ALL_GUESTS_DATA'
};

export default guestsModalActionTypes;
