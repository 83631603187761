import React from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/componentStores/GlobalModal/globalModal.actions';
import { verifyIfModalIsOpened } from '../../store/componentStores/GlobalModal/globalModal.selectors';

const withGlobalModal = (Component, key) => {
  return connect(state => {
    const connectedState = {
      globalModal: state.globalModal,
      ...(key && { isModalOpened: verifyIfModalIsOpened(key, state) })
    };

    return connectedState;
  }, mapDispatchToProps)(props => {
    return <Component {...props} />;
  });
};

const mapDispatchToProps = {
  ...actionCreators
};

export default withGlobalModal;
