// DayPasses action types

const dayPassesActionTypes = {
  SET_DAYPASSES: 'SET_DAYPASSES',
  IS_DAYPASSES_AVAILABLE: 'IS_DAYPASSES_AVAILABLE',
  UPDATE_DAYPASSES_LOADING: 'UPDATE_DAYPASSES_LOADING',
  UPDATE_DAYPASSES_METADATA: 'UPDATE_DAYPASSES_METADATA',
  SET_DAYPASSES_AVAILABLE_DATES: 'SET_DAYPASSES_AVAILABLE_DATES',
  UPDATE_DAYPASSES_FETCHING: 'UPDATE_DAYPASSES_FETCHING'
};

export default dayPassesActionTypes;
