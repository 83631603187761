// Current Lodge Reducer

import { initializeState } from '../../../utilities/storageUtils';
import currentLodgeActionTypes from './currentLodge.types';

const INITIAL_STATE = {
  resortLocation: '',
  resortLocationUrl: '',
  resortData: {}
};

const currentLodgeReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case currentLodgeActionTypes.UPDATE_CURRENT_LODGE:
      return {
        ...state,
        resortData: action.resortData,
        resortLocation: action.resortData.operaCode,
        resortLocationUrl: action.resortLocationUrl
      };
    default:
      return state;
  }
};

export default currentLodgeReducer;
