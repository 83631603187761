import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Badge, Text } from '../../../ui-kit';

import AlertSuccessIconIcon from '../../../ui-kit/icons/AlertSuccessIcon';
import theme from '../../../ui-kit/themes/theme';
import { editCopyByReplaceWithComponent } from '../../../utilities/copyFunctions';
import {
  Action,
  Amount,
  Bottom,
  Container,
  DataDescription,
  Description,
  Divider,
  Item,
  NewGuestsMessageContainer,
  NewGuestsTag,
  NewTagContainer,
  SubtotalAmount,
  Tooltip
} from './styles';

export const SummaryContent = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>;
};

SummaryContent.Action = ({ onClick, children, ...restProps }) => {
  return (
    <Action onClick={onClick} {...restProps}>
      {children}
    </Action>
  );
};

SummaryContent.Action.propTypes = {
  onClick: PropTypes.func
};

SummaryContent.Amount = ({ children, ...restProps }) => {
  return <Amount {...restProps}>{children}</Amount>;
};

SummaryContent.Bottom = ({ children, ...restProps }) => {
  return <Bottom {...restProps}>{children}</Bottom>;
};

SummaryContent.Description = ({ children, ...restProps }) => {
  return <Description {...restProps}>{children}</Description>;
};

SummaryContent.Divider = ({ ...restProps }) => {
  return <Divider {...restProps} />;
};

SummaryContent.Item = ({ children, ...restProps }) => {
  return <Item {...restProps}>{children}</Item>;
};

SummaryContent.NewTag = () => {
  return (
    <NewTagContainer>
      <Badge caps={false} variant={Badge.VARIANTS.DEEP_ORANGE_100}>
        New
      </Badge>
    </NewTagContainer>
  );
};
SummaryContent.NewGuestsTag = ({ ...restProps }) => {
  return (
    <NewGuestsTag {...restProps}>
      <Text as="span">New</Text>
    </NewGuestsTag>
  );
};
SummaryContent.NewGuestsMessage = ({ copy, newCopy }) => {
  return (
    <NewGuestsMessageContainer>
      <AlertSuccessIconIcon size="21px" color={theme.colors.successGreen} />
      <DataDescription>
        {ReactHtmlParser(editCopyByReplaceWithComponent(copy, newCopy, '<NEW_GUESTS>'))}
      </DataDescription>
    </NewGuestsMessageContainer>
  );
};

SummaryContent.SubtotalAmount = ({ children, ...restProps }) => {
  return <SubtotalAmount {...restProps}>{children}</SubtotalAmount>;
};

SummaryContent.Tooltip = ({ ref, children, ...restProps }) => {
  return <Tooltip {...restProps}>{children}</Tooltip>;
};
