// Lodge Config Reducer

import { initializeState } from '../../../utilities/storageUtils';
import lodgeConfigActionTypes from './lodgeConfig.types';

const INITIAL_STATE = {
  configFetchIsLoading: false,
  configFetchError: '',
  additionalPersonAmount: 0,
  affirmPubKey: '',
  filters: null,
  isFetchRestrictionsEnabled: false,
  isHighestRatesEnabled: false,
  isRateCalEnabled: false,
  updateSearchFullPageRefresh: false,
  isLoyaltyEnabled: false,
  loyaltyPointsToEarn: '',
  isInPrimerState: false,
  isOffersEnabled: false,
  loyaltyPointsMultiplier: 0.1,
  loyaltyEarnThresholdNights: 2,
  isLoyaltyReferralEnabled: false,
  isDiningPackagesEnhancedUIEnabled: true,
  isDynamicTileEnabled: false,
  hideOnlineCheckinText: false,
  perNightParkingFee1: '',
  perNightParkingFee2: '',
  perNightParkingFee3: '',
  parkingFeeStartDate: '',
  parkingFeeEndDate: '',
  clientCardTokenization: false,
  creditCardAuthUrl: '',
  creditEnabled: false,
  affirmEnabled: false,
  applePayEnabled: false,
  googlePayEnabled: false,
  payPalEnabled: false,
  amexEnabled: false,
  shouldLeadGenPop: '',
  parkingFeeCallout: false,
  parkingFeeCalloutTitle: '',
  parkingFeeCalloutDescription: '',
  bookingSummaryParkingFee: '',
  uffTileEnabled: false,
  isLoyaltyPointsExpirationOnProfileEnabled: false,
  isCartActive: false,
  showCaliforniaResortFeeVariation: false,
  pricePerWaterPass: 0,
  isCancelReservationEnabled: false,
  isRoomUnbundledRateWPAvailable: false,
  beginReservationsDate: null,
  maxOccupancy: 0,
  newPatchEndpoint: false,
  showPackagesInAddGuestsModal: false,
  suitePriceIncludedTaxesAndFees: [],
  taxAndFeeLabels: '',
  birthdayPartyCommonInclusionList: []
};

const lodgeConfigReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);
  switch (action.type) {
    case lodgeConfigActionTypes.LOADING_LODGE_CONFIG:
    case lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CREDIT_PACKAGE:
    case lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CMP:
    case lodgeConfigActionTypes.LOADING_LODGE_CONFIG_CART:
    case lodgeConfigActionTypes.LOADING_LODGE_CONFIG_BOOKING_WIDGET:
      return {
        ...state,
        configFetchIsLoading: action.configFetchIsLoading
      };
    case lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING:
    case lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE:
    case lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_CMP:
    case lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_CART:
    case lodgeConfigActionTypes.ERROR_LODGE_CONFIG_LOADING_BOOKING_WIDGET:
      return {
        ...state,
        configFetchError: action.configFetchError
      };
    case lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING:
    case lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_CREDIT_PACKAGE:
    case lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_CMP:
    case lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_CART:
    case lodgeConfigActionTypes.CLEAR_LODGE_CONFIG_LOADING_BOOKING_WIDGET:
      return {
        ...state,
        configFetchError: ''
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING:
      return {
        ...state,
        isLoyaltyEnabled: action.isLoyaltyEnabled,
        isInPrimerState: action.isInPrimerState,
        isOffersEnabled: action.isOffersEnabled,
        loyaltyPointsMultiplier: action.loyaltyPointsMultiplier,
        isLoyaltyReferralEnabled: action.isLoyaltyReferralEnabled,
        showCaliforniaResortFeeVariation: action.showCaliforniaResortFeeVariation,
        isLoyaltyPointsExpirationOnProfileEnabled: action.isLoyaltyPointsExpirationOnProfileEnabled,
        loyaltyPointsToEarn: action.loyaltyPointsToEarn,
        loyaltyEarnThresholdNights: action.loyaltyEarnThresholdNights,
        showPackagesInAddGuestsModal: action.showPackagesInAddGuestsModal,
        birthdayPartyCommonInclusionList: action.birthdayPartyCommonInclusionList
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_PLAN:
      return {
        ...state,
        additionalPersonAmount: action.config.additionalPersonAmount,
        affirmPubKey: action.config.affirmPubKey,
        filters: action.config.filters,
        isFetchRestrictionsEnabled: action.config.isFetchRestrictionsEnabled,
        isHighestRatesEnabled: action.config.isHighestRatesEnabled,
        isRateCalEnabled: action.config.isRateCalEnabled,
        updateSearchFullPageRefresh: action.config.updateSearchFullPageRefresh,
        includeResortFeeInSuitePrice: action.includeResortFeeInSuitePrice,
        pricePerWaterPass: action.config.pricePerWaterPass,
        isRoomUnbundledRateWPAvailable: action.config.isRoomUnbundledRateWPAvailable,
        suitePriceIncludedTaxesAndFees: action.config.suitePriceIncludedTaxesAndFees
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_CREDIT_PACKAGE:
      return {
        ...state,
        isDiningPackagesEnhancedUIEnabled: action.isDiningPackagesEnhancedUIEnabled
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_CMP:
      return {
        ...state,
        isDynamicTileEnabled: action.isDynamicTileEnabled,
        hideOnlineCheckinText: action.hideOnlineCheckinText,
        parkingFeeCallout: action.parkingFeeCallout,
        parkingFeeCalloutTitle: action.parkingFeeCalloutTitle,
        parkingFeeCalloutDescription: action.parkingFeeCalloutDescription,
        uffTileEnabled: action.uffTileEnabled,
        isCancelReservationEnabled: action.isCancelReservationEnabled
      };
    /* ABCD TEST FOR PARKING FEE */
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_PAYMENT:
      return {
        ...state,
        perNightParkingFee1: action.perNightParkingFee1,
        perNightParkingFee2: action.perNightParkingFee2,
        perNightParkingFee3: action.perNightParkingFee3,
        parkingFeeStartDate: action.parkingFeeStartDate,
        parkingFeeEndDate: action.parkingFeeEndDate,
        clientCardTokenization: action.clientCardTokenization,
        creditCardAuthUrl: action.creditCardAuthUrl,
        creditEnabled: action.creditEnabled,
        affirmEnabled: action.affirmEnabled,
        applePayEnabled: action.applePayEnabled,
        googlePayEnabled: action.googlePayEnabled,
        payPalEnabled: action.payPalEnabled,
        amexEnabled: action.amexEnabled,
        taxAndFeeLabels: action.taxAndFeeLabels,
        bookingSummaryParkingFee: action.bookingSummaryParkingFee
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_LEADGEN:
      return {
        ...state,
        shouldLeadGenPop: action.shouldLeadGenPop
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_USER_SETTINGS:
      return {
        ...state,
        newPatchEndpoint: action.newPatchEndpoint
      };
    case lodgeConfigActionTypes.RESET_LODGE_CONFIG:
      return {
        ...INITIAL_STATE
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_CART:
      return {
        ...state,
        isCartActive: action.config.isCartActive
      };
    case lodgeConfigActionTypes.SUCCESS_LODGE_CONFIG_LOADING_BOOKING_WIDGET:
      return {
        ...state,
        beginReservationsDate: action.beginReservationsDate,
        maxOccupancy: action.maxOccupancy
      };
    /* ABCD TEST FOR PARKING FEE */
    default:
      return state;
  }
};

export default lodgeConfigReducer;
