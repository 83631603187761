import { combineReducers } from 'redux';
import addAPackageActionTypes from './addAPackage.types';

const addedPackages = (state = {}, action) => {
  switch (action.type) {
    case addAPackageActionTypes.ADD_ADD_A_PACKAGE:
      return {
        ...state,
        [action.id]: {
          id: action.id,
          value: action.value,
          options: action.options,
          chargeType: action.chargeType
        }
      };
    case addAPackageActionTypes.REMOVE_ADD_A_PACKAGE:
      const { [action.id]: deletedItem, ...newState } = state;
      return newState;
    case addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES:
      return {};
    case addAPackageActionTypes.SET_ADD_A_PACKAGE_A_CABANA:
      return {
        ...state,
        [action.id]: {
          id: action.id,
          item: action.item
        }
      };
    case addAPackageActionTypes.REMOVE_ADD_A_PACKAGE_CABANA:
      const { [action.id]: deletedCabana, ...newCabanasState } = state;
      return newCabanasState;
    case addAPackageActionTypes.REMOVE_ADD_A_PACKAGE_OTHER_CABANAS:
      const filteredCabanaPkgs = {};
      Object.keys(state).forEach(key => {
        if (action.items.includes(key)) {
          filteredCabanaPkgs[key] = state[key];
        }
      });
      return filteredCabanaPkgs;
    case addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_PACKAGES_BY_CODES:
      return { ...action.addedPackages };
    default:
      return state;
  }
};

const flashMessage = (state = '', action) => {
  switch (action.type) {
    case addAPackageActionTypes.SET_ADD_A_PACKAGE_FLASH_MESSAGE:
      return action.message;
    case addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_FLASH_MESSAGE:
      return '';
    default:
      return state;
  }
};

const addAPackageReducer = combineReducers({
  addedPackages,
  flashMessage
});

export default addAPackageReducer;
