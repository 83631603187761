// DayPass actions

import { getResortLocation } from '../CurrentLodge/currentLodge.selectors';
import dayPassActionTypes from './dayPasses.types';
import AvailabilityApiClient from '../../../api/clients/AvailabilityApiClient';
import { push } from 'connected-react-router';
import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';
import { DATE_FORMATS, DAY_PASSES_TYPE } from '../../../utilities/constants';

export const validateDayPass = values => (dispatch, getState) => {
  // 1. Start loading
  const { arrivalDate, numberOfGuests, promoCode } = values;
  const payload = {
    StartDate: arrivalDate,
    EndDate: arrivalDate,
    NumberOfGuests: numberOfGuests,
    Location: getResortLocation(getState().currentLodge),
    ItemGroup: 'WPF',
    PromoCode: promoCode && promoCode.toUpperCase()
  };

  dispatch({ type: dayPassActionTypes.UPDATE_DAYPASSES_LOADING, isLoading: true });

  // 2. Get availability daypass
  (async () => {
    try {
      const apiClient = new AvailabilityApiClient();
      const availabilityDayPass = await apiClient.getAvailabilityDayPasses(payload);
      const { data } = availabilityDayPass;
      // 3. If I get data save it in the store to be used

      if (data.length !== 0) {
        const hasDayPassError = dayPassObject => {
          return dayPassObject.filter(dayPassType => dayPassType.itemCode === '').length;
        };

        if (hasDayPassError(data) > 1) {
          // 4. If I dont get data the daypass is not available
          throw new Error();
        } else {
          const daypassMetadata = [...data];

          dispatch({
            type: dayPassActionTypes.IS_DAYPASSES_AVAILABLE,
            isDayPassAvailable: true
          });
          dispatch({
            type: dayPassActionTypes.UPDATE_DAYPASSES_METADATA,
            item: daypassMetadata
          });
        }
      } else {
        dispatch({ type: dayPassActionTypes.IS_DAYPASSES_AVAILABLE, isDayPassAvailable: false });
      }
    } catch (e) {
      // 4. If I dont get data the daypass is not available
      dispatch({ type: dayPassActionTypes.IS_DAYPASSES_AVAILABLE, isDayPassAvailable: false });
    } finally {
      // 6. Stop loading data
      dispatch({ type: dayPassActionTypes.UPDATE_DAYPASSES_LOADING, isLoading: false });
    }
  })();
};
export const submitDayPass = values => dispatch => {
  // 1. save number of guests
  dispatch({ type: dayPassActionTypes.SET_DAYPASSES, ...values });
  // 3. Navigate to daypass payment form
  dispatch(push('/daypass/payment'));
};
export const checkAvailableDates = values => async (dispatch, getState) => {
  // Get values
  const { month: monthStr, year, promoCode } = values;

  dispatch({
    type: dayPassActionTypes.UPDATE_DAYPASSES_FETCHING,
    fetchData: {
      isDaypassFetching: true,
      monthToFetch: monthStr
    }
  });

  const month = +monthStr;

  const todayDate = new Date();
  const startingDate = new Date(+year, month - 1, 1);
  const endingDate = new Date(+year, month, 0);

  //Validate if date is before than current date
  if (todayDate.getFullYear() > year) {
    dispatch({
      type: dayPassActionTypes.SET_DAYPASSES_AVAILABLE_DATES,
      availabilityData: { promotion: '', dayPasses: [] },
      date: values
    });
    return dispatch({
      type: dayPassActionTypes.UPDATE_DAYPASSES_FETCHING,
      fetchData: {
        isDaypassFetching: false
      }
    });
  } else if (todayDate.getMonth() + 1 > month && todayDate.getFullYear() === year) {
    dispatch({
      type: dayPassActionTypes.SET_DAYPASSES_AVAILABLE_DATES,
      availabilityData: { promotion: '', dayPasses: [] },
      date: values
    });
    return dispatch({
      type: dayPassActionTypes.UPDATE_DAYPASSES_FETCHING,
      fetchData: {
        isDaypassFetching: false
      }
    });
  }

  const isCurrentMonth = month === todayDate.getMonth() + 1 && year === todayDate.getFullYear();

  const StartDate = GwDatesWrapper.format(isCurrentMonth ? todayDate : startingDate, DATE_FORMATS.default);
  const EndDate = GwDatesWrapper.format(endingDate, DATE_FORMATS.default);

  const payload = {
    StartDate,
    EndDate,
    NumberOfGuests: 1,
    Location: getResortLocation(getState().currentLodge),
    ItemGroup: 'WPF',
    PromoCode: promoCode
  };

  const apiClient = new AvailabilityApiClient('v2.1');
  let result = { data: {} };

  try {
    result = await apiClient.getAvailabilityDayPasses(payload);
  } catch (error) {}

  dispatch({
    type: dayPassActionTypes.SET_DAYPASSES_AVAILABLE_DATES,
    availabilityData: result.data,
    date: values
  });

  dispatch({
    type: dayPassActionTypes.UPDATE_DAYPASSES_FETCHING,
    fetchData: {
      isDaypassFetching: false
    }
  });
};
export const submitDayPassAndRedirect = values => dispatch => {
  const { arrivalDate, numberOfGuests, promotion, qualifyingId, passData, promoCodeData } = values;
  const title = passData.itemCode === DAY_PASSES_TYPE.fullDay ? 'Full Day Pass' : 'Half Day Pass';
  const getTitleAndRate = () => {
    if (promotion) {
      const promo = promotion.toUpperCase();
      return {
        promoCode: promo,
        selectedTitle: title,
        selectedRateCode: passData.rateCode
      };
    }

    return {
      promoCode: '',
      selectedTitle: title,
      selectedRateCode: passData.rateCode
    };
  };
  const { promoCode, selectedTitle, selectedRateCode } = getTitleAndRate();
  const payload = {
    arrivalDate,
    numberOfGuests,
    selectedTitle,
    selectedRate: passData.roomRateTotal,
    selectedTaxesAndFees: passData.taxesAndFees,
    selectedItemCode: passData.itemCode,
    selectedRateCode,
    promoCode,
    selectedDescription: passData.description,
    parkingFee: passData.totalParkingFee,
    resortFee: passData.totalResortFee,
    sustainabilityFee: passData.totalSustainabilityFee,
    totalTaxesAndFees: passData.totalTaxesAndFees,
    item: {
      date: arrivalDate,
      roomRateTotal: passData.roomRateTotal,
      itemCode: passData.itemCode,
      taxesAndFees: passData.taxesAndFees
    },
    ...(promoCodeData && promoCodeData),
    ...(qualifyingId && { qualifyingId })
  };
  // 1. save number of guests
  dispatch({ type: dayPassActionTypes.SET_DAYPASSES, ...payload });
  // 3. Navigate to daypass payment form
  dispatch(push('/daypass/payment'));
};
