import { initializeState } from '../../../utilities/storageUtils';
import datesActionTypes from './dates.types';

const INITIAL_STATE = {
  checkinDateSelection: undefined,
  checkoutDateSelection: undefined,
  isFetchingSipDates: false,
  sipDates: {},
  sipRatesError: null
};

const datesReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);
  switch (action.type) {
    case datesActionTypes.UPDATE_DATES:
      return {
        ...state,
        checkinDateSelection: action.checkinDateSelection,
        checkoutDateSelection: action.checkoutDateSelection
      };
    case datesActionTypes.CLEAR_DATES:
      return INITIAL_STATE;
    case datesActionTypes.FETCHING_DATES_SIP:
      return {
        ...state,
        isFetchingSipDates: action.fetchSipDates
      };
    case datesActionTypes.UPDATE_DATES_SIP:
      return {
        ...state,
        sipDates: action.sipDates
      };
    case datesActionTypes.UPDATE_DATES_SIP_ERROR:
      return {
        ...state,
        sipRatesError: action.sipStatus
      };
    default:
      return state;
  }
};

export default datesReducer;
