// Add a Package Selectors
/**
 * Get all added packages data.
 * @param {Object} state Added packages data.
 */
export const getAddedpackages = state => state.addedPackages;

/**
 * Get flash message from add a package.
 * @param {Object} state Added packages data.
 */
export const getFlashMessage = state => state.flashMessage;
