// Deal action types

const dealActionTypes = {
  GET_DEAL_SUCCESS: 'GET_DEAL_SUCCESS',
  GET_DEAL_LOADING: 'GET_DEAL_LOADING',
  GET_DEAL_ERROR: 'GET_DEAL_ERROR',
  CLEAR_DEAL: 'CLEAR_DEAL'
};

export default dealActionTypes;
