import { getOfferCodeErrorDetails } from '../../../../utilities/planUtils';
import { logOfferCodeError } from '../../../../utilities/analytics';
import offerActionTypes from '../../../../store/componentStores/Offer/offer.types';
import plansActionTypes from '../../../../store/componentStores/Plans/plans.types';

export const onOfferCodeError = ({ type, offerCodeError, errorMessage, getState }) => {
  const shouldLogOfferCodeError =
    type === offerActionTypes.CREATE_OFFER_ERROR || type === plansActionTypes.GET_PLANS_AVAILABILITY_INFO_SUCCESS;
  const isAvailabilityError = type === plansActionTypes.GET_PLANS_AVAILABILITY_INFO_SUCCESS;
  const isOfferCodeError = type === offerActionTypes.CREATE_OFFER_ERROR;

  if (!shouldLogOfferCodeError) return;

  if (isAvailabilityError) {
    const { dates: { checkinDateSelection, checkoutDateSelection } = {} } = getState();
    const { errorCode, offerCode } = offerCodeError || {};

    const offerCodeErrorMessage = getOfferCodeErrorDetails({
      offerCode,
      offerCodeError: errorCode,
      checkIn: checkinDateSelection,
      checkOut: checkoutDateSelection
    });

    if (offerCodeErrorMessage && offerCodeErrorMessage.message) {
      logOfferCodeError(offerCodeErrorMessage.message);
    }
  }

  if (isOfferCodeError) {
    logOfferCodeError(errorMessage);
  }
};
