import { initializeState } from '../../../utilities/storageUtils';
import preCheckInActionTypes from './preCheckIn.types';

export const PRE_CHECK_IN_STEP = {
  SUMMARY: 'SUMMARY',
  DINING: 'DINING',
  ATTRACTIONS: 'ATTRACTIONS',
  ADVENTURE_PASS: 'ADVENTURE_PASS',
  TRANSITION: 'TRANSITION'
};

const INITIAL_STATE = {
  preCheckInStep: PRE_CHECK_IN_STEP.SUMMARY,
  addedPackagesFailed: 0,
  addedPackages: [],
  virdeeRedirectionFailed: 0
};

const preCheckInReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    // Get Resort Locations SUCCESS
    case preCheckInActionTypes.UPDATE_PRE_CHECK_IN_STEP:
      return {
        ...state,
        preCheckInStep: action.step
      };
    case preCheckInActionTypes.UPDATE_PRE_CHECK_IN_ADDED_PACKAGES_FAILED:
      return {
        ...state,
        addedPackagesFailed: ++state.addedPackagesFailed
      };
    case preCheckInActionTypes.RESET_PRE_CHECK_IN_ADDED_PACKAGES_FAILED:
      return {
        ...state,
        addedPackagesFailed: 0
      };
    case preCheckInActionTypes.UPDATE_PRE_CHECK_IN_NEW_PACKAGES:
      return {
        ...state,
        addedPackages: action.packages
      };
    case preCheckInActionTypes.UPDATE_PRE_CHECK_IN_VIRDEE_REDIRECTION_FAILED:
      return {
        ...state,
        virdeeRedirectionFailed: ++state.virdeeRedirectionFailed
      };
    default:
      return state;
  }
};

export default preCheckInReducer;
