// Current Lodge Actions

import { getResortLocationUrl } from '../../../utilities/resortLocation';
import currentLodgeActionTypes from './currentLodge.types';

export const updateCurrentLodge = lodges => dispatch => {
  const resortLocationUrl = getResortLocationUrl();

  const locationFromUrl = resortLocationUrl.replace(/\//g, '');

  const currentLocationData = Object.values(lodges).find(location => location.url === locationFromUrl);

  if (currentLocationData) {
    dispatch({
      type: currentLodgeActionTypes.UPDATE_CURRENT_LODGE,
      resortData: currentLocationData,
      resortLocationUrl: resortLocationUrl
    });
  }
};
