// Plans Selectors

import { PERSONALIZATION_RULES } from '../../../utilities/constants';
import get from 'lodash-es/get';

export const getCalendarRangeRestrictionList = state => {
  return state.selectedSuiteRangeRestrictions;
};

export const getOfferCodeError = state => {
  return state.offerCodeError;
};

export const getIsBookingWidgetFocused = state => {
  return state.isBookingWidgetFocused;
};

/**
 * Returns the filters applied labels.
 * @param {Object} state Plan store.
 * @returns {Object} Filters applied.
 */
export const getFiltersAppliedLabels = state => {
  return state.filtersApplied;
};

/**
 * Returns the old filters applied labels.
 * @param {Object} state Plan store.
 * @returns {Object} Old filters applied.
 */
export const getOldFiltersAppliedLabels = state => {
  return state.oldFiltersApplied;
};

/**
 * Returns the sorting criteria.
 * @param {Object} state Plan store.
 * @returns {string} Actual sorting criteria.
 */
export const getSortOrder = state => {
  return state.sorting;
};

export const getAvailabilityList = state => {
  return state.availabilityList;
};

/**
 * Returns the Interactions personalization data from the availability reponse
 * @param {Object} state
 */
export const getInteractionsData = state => {
  const interactions =
    state.personalizationResponses &&
    state.personalizationResponses.filter(pr => pr.rule === PERSONALIZATION_RULES.interactions);

  return interactions && interactions.length > 0 && interactions[0].data;
};

export const getRecentSearchesList = state => {
  return get(state, 'recentSearchesList', []);
};

export const getRecentSearchesLoading = state => {
  const getRecentSearchesLoading = get(state, 'searchesGetReqIsLoading', false);
  const delRecentSearchesLoading = get(state, 'searchesDelReqIsLoading', false);
  return getRecentSearchesLoading || delRecentSearchesLoading;
};

export const getDealsSummary = state => {
  return state.dealsSummary;
};

export const getIsAPScall = state => {
  return state.isAPScall;
};

export const getIsPaymentLeaveModal = state => {
  return state.leavePayment;
};

export const getLowestRateCalendarList = state => {
  return state.lowestRateCalendarList;
};

export const getIsCaliforniaResident = state => {
  return state?.isCaliforniaResident;
};

export const getIsTwoRateTypesSuites = state => {
  return state?.isTwoRateTypesSuites;
};

export const getTopFiveSuites = state => {
  return state?.topFiveSuites;
};

export const getAvRangeBySuiteIsLoading = state => state?.getAvRangeBySuiteIsLoading;

export const getItemQuantityState = state => state.itemQuantity;
