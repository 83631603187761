// Add a Package action types

const addAPackageActionTypes = {
  ADD_ADD_A_PACKAGE: 'ADD_ADD_A_PACKAGE',
  REMOVE_ADD_A_PACKAGE: 'REMOVE_ADD_A_PACKAGE',
  SET_ADD_A_PACKAGE_FLASH_MESSAGE: 'SET_ADD_A_PACKAGE_FLASH_MESSAGE',
  CLEAR_ADD_A_PACKAGE_FLASH_MESSAGE: 'CLEAR_ADD_A_PACKAGE_FLASH_MESSAGE',
  CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES: 'CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES',
  SET_ADD_A_PACKAGE_A_CABANA: 'SET_ADD_A_PACKAGE_A_CABANA',
  REMOVE_ADD_A_PACKAGE_CABANA: 'REMOVE_ADD_A_PACKAGE_CABANA',
  REMOVE_ADD_A_PACKAGE_OTHER_CABANAS: 'REMOVE_ADD_A_PACKAGE_OTHER_CABANAS',
  CLEAR_ADD_A_PACKAGE_PACKAGES_BY_CODES: 'CLEAR_PACKAGES_BY_CODES__FIX_DUPLICATE'
};

export default addAPackageActionTypes;
