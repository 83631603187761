// Offer selectors

import { formatOffer } from '../../../components/LoyaltySummary/utilities/functions';
import { getDefaultOfferCode } from './offer.reducer';

export const getOfferCode = state => getDefaultOfferCode(state.code);

export const getOfferCodeDescription = state => state.description;

export const getOfferCodeErrorMessage = state => state.errorMessage;

export const getOfferCodeStatus = state => state.validOffer;

export const getActiveOffers = state => state.activeOffers;

export const getEmailActiveOffers = state => state.emailOffers;

export const getTotalActiveOffers = state => {
  const activeOffers = state.offer.activeOffers;
  if (activeOffers.length > 0) {
    const userOffers = state.account?.currentLoggedInUser?.offerList;
    const offerstotal = activeOffers?.reduce((accumulator, currentValue) => {
      const offer = userOffers?.find(offer => offer.id === currentValue);
      return accumulator + offer?.dollarValue;
    }, 0);
    return offerstotal;
  }
  return 0;
};

export const getAvailableOffersBySearch = state => {
  const availableOffers = [];
  const userOffers = state.account.currentLoggedInUser.offerList || [];
  const { checkinDateSelection, checkoutDateSelection } = state.dates;
  userOffers.forEach(offer => {
    const formatedOffer = formatOffer(offer, false, checkinDateSelection, checkoutDateSelection);
    if (formatedOffer.searchIsWithinOfferDates === true) {
      availableOffers.push(formatedOffer);
    }
  });
  return availableOffers;
};
