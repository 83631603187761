// Lodge Locations Selectors

export const getLocationList = state => state.locationList;

export const getLocation = (state, locationCode) => state?.locationList[locationCode] || {};

export const getLocationPhone = (state, locationCode) => getLocation(state, locationCode).phone;

export const getLocationURL = (state, locationCode) => getLocation(state, locationCode).url;

export const getLocationDisplayName = (state, shorthandOrCode) => getLocation(state, shorthandOrCode).display;

/**
 * Check if the user has a location already selected
 * @param {array} locationSelectedValues Locations selected by the user
 * @param {string} locationCode Property code to check if the user has selected
 * @returns bool whether the user has a location already selected
 */
export const containsLocation = (locationSelectedValues, locationCode) => {
  return locationSelectedValues.includes(locationCode);
};

/**
 * Get locations/properties by region in a dictionary
 * @returns dict
 */
export const getPropertiesByRegion = state => {
  let regions = Object.entries(state.locationList).map(([key, value]) => {
    return value.region;
  });
  regions = [...new Set(regions)];

  const locations = Object.entries(state.locationList).map(([key, value]) => {
    return {
      display: value.display,
      region: value.region,
      operaCode: value.operaCode
    };
  });

  let propertiesByRegion = {};

  regions.forEach(region => {
    propertiesByRegion[region] = locations.filter(location => location.region === region);
  });

  return propertiesByRegion;
};

/**
 * Get regions selected by the user
 * @param {array} locationSelectedValues Locations selected by the user
 * @returns array containing the names of the regions where the user is subscribed
 */
export const regionsSelected = (state, locationSelectedValues) => {
  const propsByRegion = getPropertiesByRegion(state);
  const filtered = Object.keys(propsByRegion).filter(key =>
    propsByRegion[key].some(location => containsLocation(locationSelectedValues, location.operaCode))
  );

  return filtered;
};
