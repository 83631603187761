// Reservations action types

const reservationsActionTypes = {
  GET_RESERVATION_BY_ID_SUCCESS: 'GET_RESERVATION_BY_ID_SUCCESS',
  GET_RESERVATION_BY_ID_LOADING: 'GET_RESERVATION_BY_ID_LOADING',
  GET_RESERVATION_BY_ID_ERROR: 'GET_RESERVATION_BY_ID_ERROR',
  GET_RESERVATION_BY_ID_FINISH: 'GET_RESERVATION_BY_ID_FINISH',
  GET_RESERVATION_INFO_SUCCESS: 'GET_RESERVATION_INFO_SUCCESS',
  GET_RESERVATION_INFO_LOADING: 'GET_RESERVATION_INFO_LOADING',
  GET_RESERVATION_INITIAL_INFO_LOADING: 'GET_RESERVATION_INITIAL_INFO_LOADING',
  GET_RESERVATION_INFO_ERROR: 'GET_RESERVATION_INFO_ERROR',
  GET_RESERVATION_INFO_FINISH: 'GET_RESERVATION_INFO_FINISH',
  CREATE_RESERVATION_LOADING: 'CREATE_RESERVATION_LOADING',
  CREATE_RESERVATION_ERROR: 'CREATE_RESERVATION_ERROR',
  CREATE_RESERVATION_SUCCESS: 'CREATE_RESERVATION_SUCCESS',
  CREATE_RESERVATION_TEMP_USER: 'CREATE_RESERVATION_TEMP_USER',
  CLEAR_RESERVATION_ERROR: 'CLEAR_RESERVATION_ERROR',
  PUT_RESERVATION_CANCEL_SUCCESS: 'PUT_RESERVATION_CANCEL_SUCCESS',
  PUT_RESERVATION_CANCEL_UPDATE: 'PUT_RESERVATION_CANCEL_UPDATE',
  SET_RESERVATION_LAST_NAME_FIND_BY_ID: 'SET_RESERVATION_LAST_NAME_FIND_BY_ID',
  SET_RESERVATION_KEY_FIND_RESERVATION_BY_ID: 'SET_RESERVATION_KEY_FIND_RESERVATION_BY_ID',
  GTM_RESERVATION_NEW_OR_DAYPASS: 'GTM_RESERVATION_NEW_OR_DAYPASS'
};

export default reservationsActionTypes;
