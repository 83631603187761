// Lodge Locations reducer

import { initializeState } from '../../../utilities/storageUtils';
import lodgeLocationsActionTypes from './lodgeLocations.types';

const INITIAL_STATE = {
  lodgesLocationsFetchIsLoading: false,
  lodgesLocationsFetchError: '',
  locationList: {}
};

const lodgeLocationsReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    // Get Resort Locations SUCCESS
    case lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationList: action.locationList,
        requestFailed: false,
        errorMessage: ''
      };
    // Get Resort Locations LOADING
    case lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Get Resort Locations FAIL
    case lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_ERROR:
      return {
        ...state,
        locationsList: {},
        requestFailed: action.requestFailed,
        errorMessage: action.errorMessage
      };
    case lodgeLocationsActionTypes.CLEAR_LODGE_LOCATIONS_LOADING_CONFIG:
      return {
        ...state,
        errorMessage: ''
      };

    default:
      return state;
  }
};

export default lodgeLocationsReducer;
