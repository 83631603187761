// Account action types

const accountActionTypes = {
  RESET_ACCOUNT_NEW_TO_INITIAL_STATE: 'RESET_ACCOUNT_NEW_TO_INITIAL_STATE',
  POST_ACCOUNT_FORGOT_PWD_INFO: 'POST_ACCOUNT_FORGOT_PWD_INFO',
  POST_ACCOUNT_FORGOT_PWD_INFO_SUCCESS: 'POST_ACCOUNT_FORGOT_PWD_INFO_SUCCESS',
  POST_ACCOUNT_FORGOT_PWD_INFO_FAILURE: 'POST_ACCOUNT_FORGOT_PWD_INFO_FAILURE',
  POST_ACCOUNT_CHANGE_PWD_INFO_SUCCESS: 'POST_ACCOUNT_CHANGE_PWD_INFO_SUCCESS',
  POST_ACCOUNT_CHANGE_PWD_INFO_LOADING: 'POST_ACCOUNT_CHANGE_PWD_INFO_LOADING',
  POST_ACCOUNT_CHANGE_PWD_INFO_ERROR: 'POST_ACCOUNT_CHANGE_PWD_INFO_ERROR',
  POST_ACCOUNT_UNLOCK_SUCCESS: 'POST_ACCOUNT_UNLOCK_SUCCESS',
  POST_ACCOUNT_UNLOCK_LOADING: 'POST_ACCOUNT_UNLOCK_LOADING',
  POST_ACCOUNT_UNLOCK_ERROR: 'POST_ACCOUNT_UNLOCK_ERROR',
  CURRENT_ACCOUNT_USER_IS_LOGGED_IN: 'CURRENT_ACCOUNT_USER_IS_LOGGED_IN',
  CURRENT_ACCOUNT_LOGGED_IN_USER: 'CURRENT_ACCOUNT_LOGGED_IN_USER',
  POST_ACCOUNT_NEW_SUCCESS: 'POST_ACCOUNT_NEW_SUCCESS',
  POST_ACCOUNT_NEW_LOADING: 'POST_ACCOUNT_NEW_LOADING',
  POST_ACCOUNT_NEW_ERROR: 'POST_ACCOUNT_NEW_ERROR',
  LOGIN_ACCOUNT_ERROR_TYPE: 'LOGIN_ACCOUNT_ERROR_TYPE',
  RESET_ACCOUNT_METHODS: 'RESET_ACCOUNT_METHODS',
  RESET_ACCOUNT_PW_FEEDBACK: 'RESET_ACCOUNT_PW_FEEDBACK',
  LOYALTY_ACCOUNT_OPT_IN: 'LOYALTY_ACCOUNT_OPT_IN',
  UPDATE_ACCOUNT_MEMBER_ID_AND_REFERRAL_CODE: 'UPDATE_ACCOUNT_MEMBER_ID_AND_REFERRAL_CODE',
  REMOVE_ACCOUNT_ALL_ACTIVE_OFFERS_TYPE: 'REMOVE_ALL_ACTIVE_OFFERS_TYPE__FIX_DUPLICATE',
  UPDATE_ACCOUNT_USER_POINTS_AND_OFFERS: 'UPDATE_ACCOUNT_USER_POINTS_AND_OFFERS',
  GET_ACCOUNT_USER_POINTS_HISTORY: 'GET_ACCOUNT_USER_POINTS_HISTORY',
  POST_ACCOUNT_USER_NOT_FOUND: 'POST_ACCOUNT_USER_NOT_FOUND'
};

export default accountActionTypes;
