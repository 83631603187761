// TODO - revisit this component to fix how hooks are working
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/componentStores/IEModal/ieModal.actions';
import withGlobalModal from '../GlobalModal/withGlobalModal';
import { MODAL_KEYS } from '../GlobalModal/modalSource';

const IEModal = ({ isAlreadyShown, setAlreadyShown, openModal }) => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIEBrowser = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

    if (isIEBrowser && !isAlreadyShown) {
      openModal(
        {
          keyContentComponent: MODAL_KEYS.IE_MODAL,
          params: {}
        },
        true
      );
      setAlreadyShown(true);
    }
  }, []);

  return null;
};

export default connect(
  state => {
    return {
      ...state.ieModal
    };
  },
  {
    ...actionCreators
  }
)(withGlobalModal(IEModal));
