import React, { lazy, Suspense, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { Switch, withRouter } from 'react-router';
import { Route, useLocation } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import AppConfiguration from './components/AppConfiguration/AppConfiguration';
import { CartContainer } from './components/Cart';
import FloatingMessage from './components/FloatingMessage/FloatingMessage';
import IEModal from './components/IEModal';
import theme from './components/ui-kit/themes/theme';
import GlobalStyle from './GlobalStyle';
import routes from './routes';
import withAppInsights from './utilities/applicationInsights';
import { checkCookie, getCookie, setCookie } from './utilities/cookiesUtils';
import isAuthorMode from './utilities/isAuthorMode';

const DynamicFlow = lazy(() => import(/* webpackChunkName: "DynamicFlow" */ './flows/DynamicFlow/DynamicFlow'));

const BookTab = lazy(() => import(/* webpackChunkName: "BookTab" */ './components/BookTab/index'));
const EmailCapture = lazy(() => import(/* webpackChunkName: "EmailCapture" */ './components/EmailCapture'));
const AppLayout = lazy(() => import(/* webpackChunkName: "AppLayout" */ './layouts/AppLayout'));
const SummaryLayout = lazy(() => import(/* webpackChunkName: "SummaryLayout" */ './layouts/SummaryLayout'));
const NoMatchLayout = lazy(() => import(/* webpackChunkName: "NoMatchLayout" */ './layouts/NoMatchLayout'));
const HeaderWithTwoColumnLayout = lazy(() =>
  import(/* webpackChunkName: "HeaderWithTwoColumnLayout" */ './layouts/HeaderWithTwoColumnLayout')
);
const NavigationMenuLayout = lazy(() =>
  import(
    /* webpackChunkName: "NavigationMenuLayout" */
    './layouts/NavigationMenuLayout'
  )
);
const DayPassesPicker = lazy(() =>
  import(/* webpackChunkName: "DayPassesPicker" */ './components/DayPasses/DayPassesPicker')
);
const DayPassesHeader = lazy(() =>
  import(/* webpackChunkName: "DayPassesHeader" */ './components/DayPasses/DayPassesHeader')
);
const DayPassesInformation = lazy(() =>
  import(/* webpackChunkName: "DayPassesInformation" */ './components/DayPasses/DayPassesInformation')
);
const PaymentForm = lazy(() => import(/* webpackChunkName: "PaymentForm" */ './components/PaymentForm'));
const GlobalModal = lazy(() => import(/* webpackChunkName: "GlobalModal" */ './components/GlobalModal'));
const PlanSearchForm = lazy(() => import(/* webpackChunkName: "PlanSearchForm" */ './components/PlanSearchForm'));
const PreCheckIn = lazy(() => import(/* webpackChunkName: "Reservation" */ './pages/PreCheckIn'));
const Reservations = lazy(() => import(/* webpackChunkName: "Reservations" */ './pages/Reservations'));
const MyReservations = lazy(() =>
  import(/* webpackChunkName: "MyReservations" */ './pages/Reservations/MyReservations')
);
const SearchReservations = lazy(() =>
  import(/* webpackChunkName: "SearchReservations" */ './pages/Reservations/SearchReservations')
);
const ReferAFriendPage = lazy(() => import(/* webpackChunkName: "ReferAFriend" */ './pages/User/ReferAFriendPage'));
const UserProfile = lazy(() => import(/* webpackChunkName: "UserProfile" */ './pages/User/Profile'));
const EventCalendar = lazy(() => import(/* webpackChunkName: "EventCalendar" */ './pages/EventCalendar'));
const MyRecentSearches = lazy(() =>
  import(/* webpackChunkName: "MyRecentSearches" */ './pages/RecentSearches/MyRecentSearches')
);
const ChangePassword = lazy(() => import(/* webpackChunkName: "ChangePassword" */ './pages/ChangePassword'));
const UpdatePassword = lazy(() =>
  import(/* webpackChunkName: "UpdatePassword" */ './pages/ChangePassword/UpdatePassword')
);
const UnlockAccount = lazy(() => import(/* webpackChunkName: "UnlockAccount" */ './pages/UnlockAccount'));
const ReservationDetail = lazy(() => import(/* webpackChunkName: "ReservationDetail" */ './pages/ReservationDetail'));
const MyReservationsPage = lazy(() => import(/* webpackChunkName: "MyReservationsPage" */ './pages/MyReservations'));
const BirthdayParty = lazy(() => import(/* webpackChunkName: "BirthdayParty" */ './components/BirthdayParty'));
const MyPreferences = lazy(() => import(/* webpackChunkName: "MyPreferences" */ './pages/User/Preferences'));

const DaypassBooking = lazy(() => import(/* webpackChunkName: "DaypassBooking" */ './pages/Daypass/DaypassBooking'));
const LandingPageForm = lazy(() => import(/* webpackChunkName: "LandingPageForm" */ './pages/Loyalty/LandingPageForm'));
const EmailQueuePage = lazy(() => import(/* webpackChunkName: "LandingPageForm" */ './pages/EmailQueue'));
const MysteryModal = lazy(() => import(/* webpackChunkName: "MysteryModal" */ './components/MysteryModal'));
const EventPlanner = lazy(() => import(/* webpackChunkName: "EventPlanner" */ './pages/EventPlanner'));
const Folio = lazy(() => import(/* webpackChunkName: "Folio" */ './pages/Folio'));

Modal.setAppElement(document.getElementById('root'));

const App = ({ history, flowName }) => {
  if (!(flowName)) console.warn('No flowName attribute found in HTML');
  const flowAudience = window.flowAudience ?? 'ALL';

  
  history.listen(() => {
    // It'll be used by the AOS library being used in the AEM project
    // that needs to trigger the footer animation when we're switching
    // between React routes
    window.dispatchEvent(new Event('resize'));
  });
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    //This if statement to setup the did cookie
    //==========================================
    if (!checkCookie('did')) {
      setCookie('did', uuidv4(), 3650);
    }
    //This if statement is to setup the eid cookie
    //=============================================
    if (searchParams.get('eid') !== null) {
      //If the eid query string exists and the eid cookie doesn't exits create one
      //===========================================================================
      if (!checkCookie('eid')) setCookie('eid', searchParams.get('eid'), 3650);
      //If eid cookie exists but is different from the query string update the cookie with the query string
      //====================================================================================================
      if (
        getCookie('eid') !== null &&
        searchParams.get('eid') !== null &&
        searchParams.get('eid') !== getCookie('eid')
      ) {
        setCookie('eid', searchParams.get('eid'), 3650);
      }
    }
    //This if statement is to setup the pastGuest cookie
    //===================================================
    if (searchParams.get('pg') !== null) {
      //If the pg query string exists and the pastGuest cookie doesn't exits create one
      //==================================================================================
      if (!checkCookie('pastGuest')) setCookie('pastGuest', searchParams.get('pg'), 3650);
      //If pastGuest cookie exists but has different value from the pg from the query string it will update the cookie
      //===============================================================================================================
      if (
        getCookie('pastGuest') !== null &&
        searchParams.get('pg') !== null &&
        searchParams.get('pg') !== getCookie('pastGuest')
      ) {
        setCookie('pastGuest', searchParams.get('pg'), 3650);
      }
    }
  }, [history.location.pathname, searchParams]);

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.lg });


  return (
    <AppConfiguration>
      <div className="booking-engine-root-container">
        <GlobalStyle />
        <IEModal />
        <Suspense fallback={<div></div>}>
          {searchParams.get('mystery') === 'true' ? <MysteryModal /> : null}
          <GlobalModal />
          <CartContainer />
          <FloatingMessage />
          {!isAuthorMode() && <EmailCapture />}

          <Route
            path={[
              routes.blank,
              routes.birthdays,
              routes.reservations.self.toString(),
              routes.myRecentSearches,
              routes.profile.toString(),
              routes.eventCalendar,
              routes.daypass.self.toString()
            ]}
            exact
            component={BookTab}
          />
          <Switch>
            <AppLayout
              exact
              path={[routes.blank, routes.home]}
              shouldUnWrapFromContainer
              component={matchProps => {
                return (
                  <PlanSearchForm shouldHideOnMobile shouldOpenCalendarUp shouldRemoveBorderRadius {...matchProps} />
                );
              }}
            />
            <AppLayout
              exact
              path={[
                routes.plan.self,                
                routes.plan.activities, 
                routes.plan.dining, 
                routes.plan.payment, 
                routes.birthdayParties.partySelect,
                routes.birthdayParties.addons,
                routes.birthdayParties.guestInfo,
                routes.birthdayParties.payment
              ]}
              shouldPreventOverflowX={isTablet} 
              component={matchProps => {
                return (
                  <DynamicFlow flowAudience={flowAudience}  flowName={flowName} {...matchProps} />
                );
              }}
            />
            <HeaderWithTwoColumnLayout
              exact
              path={routes.daypass.self}
              header={DayPassesHeader}
              mediumFor={DayPassesPicker}
              mediumEight={DayPassesInformation}
            />
            <AppLayout exact path={routes.promoSignUp} component={EmailQueuePage} />
            <AppLayout exact path={routes.reservation.detail.folio} component={Folio} />
            <AppLayout exact path={routes.daypass.booking} component={DaypassBooking} />
            <AppLayout exact path={routes.loyaltyLandingPageForm} component={LandingPageForm} />
            <AppLayout exact path={routes.eventCalendar} component={EventCalendar} />
            <SummaryLayout exact path={routes.daypass.payment} component={PaymentForm} />
            <AppLayout
              exact
              shouldUnWrapFromContainer
              path={[routes.plan.paymentSuccess, routes.daypass.paymentSuccess]}
              component={ReservationDetail}
            />
            <NavigationMenuLayout exact path={routes.profile.self} component={UserProfile} />
            <NavigationMenuLayout exact path={routes.referAFriend} component={ReferAFriendPage} />
            <AppLayout exact path={routes.birthdays} component={BirthdayParty} />
            <AppLayout exact path={routes.reservations.self} component={Reservations} />
            <AppLayout exact path={routes.reservations.search} component={SearchReservations} />
            <NavigationMenuLayout exact path={routes.reservations.myReservations.self} component={MyReservations} />
            <NavigationMenuLayout exact path={routes.profile.myPreferences} component={MyPreferences} />
            <AppLayout
              exact
              shouldUnWrapFromContainer
              path={[
                routes.reservations.myReservations.detail.self,
                routes.reservations.myReservations.detail.packagemodal,
                routes.reservations.myReservations.detail.addPackage
              ]}
              component={MyReservationsPage}
            />
            <AppLayout
              shouldUnWrapFromContainer
              exact
              path={routes.reservation.detail.checkIn}
              component={PreCheckIn}
            />
            <AppLayout
              shouldUnWrapFromContainer
              exact
              path={routes.referAFriendLoggedOut}
              component={ReferAFriendPage}
            />
            <AppLayout path={routes.forgotPassword} component={ChangePassword} exact />
            <AppLayout path={routes.changePassword} component={UpdatePassword} exact />
            <AppLayout path={routes.unlockAccount} component={UnlockAccount} exact />
            <NavigationMenuLayout exact path={routes.myRecentSearches} component={MyRecentSearches} />
            <AppLayout exact path={routes.venues.planner} component={EventPlanner} />
            <AppLayout
              exact
              path={[routes.planSearchForm.t2, routes.planSearchForm.t3]}
              component={matchProps => {
                return (
                  <>
                    <BookTab {...matchProps} />
                    <PlanSearchForm shouldHideOnMobile {...matchProps} />
                  </>
                );
              }}
            />
            <AppLayout exact path={[routes.planSearchForm]} component={PlanSearchForm} />

            {/* This layout is the catch-all for non-Plan components that still renders dynamic features on AEM */}
            <NoMatchLayout />
          </Switch>
        </Suspense>
      </div>
    </AppConfiguration>
  );
};

export default withAppInsights(withRouter(App));
