// Deal reducer

import { initializeState } from '../../../utilities/storageUtils';
import dealActionTypes from './deal.types';

const INITIAL_STATE = {
  dealData: {},
  sendingGet: false,
  getFailed: false,
  getErrorMessage: ''
};

const dealReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  // Get Book information reducers
  switch (action.type) {
    // Post data request succeded
    case dealActionTypes.GET_DEAL_SUCCESS:
      return {
        ...state,
        dealData: action.dealData,
        sendingGet: false,
        getFailed: false,
        getErrorMessage: ''
      };
    // The request is currently running
    case dealActionTypes.GET_DEAL_LOADING:
      return {
        ...state,
        sendingGet: action.sendingGet
      };
    // The Post request failed
    case dealActionTypes.GET_DEAL_ERROR:
      return {
        ...state,
        dealData: {},
        sendingGet: false,
        getFailed: true,
        getErrorMessage: action.getErrorMessage
      };

    // handle the offerCode clear event
    case dealActionTypes.CLEAR_DEAL:
      return {
        ...state,
        dealData: {}
      };

    default:
      return state;
  }
};

export default dealReducer;
