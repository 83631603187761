import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Animation, Box, Text } from '../../../../../ui-kit';
import { SUMMARY_IMAGES } from '../assets/images';
import { CartLogoContainer, CartLogoCounter, CartLogoImg, CartLogoWithAnimation } from '../styles';

const propTypes = {
  packagesCount: PropTypes.number,
  toggleOpen: PropTypes.func
};

const defaultProps = {
  packagesCount: 0,
  toggleOpen: () => {}
};

const CartLogo = ({ packagesCount = 0, toggleOpen, overlayVisible }) => {
  const [showAnimation, setShowAnimation] = React.useState(false);
  const currentPackageCount = useRef(packagesCount);

  useEffect(() => {
    if (packagesCount > currentPackageCount.current) {
      setShowAnimation(true);
      currentPackageCount.current = packagesCount;
      const closeAnimation = setTimeout(() => {
        setShowAnimation(false);
      }, 1500);

      return () => {
        clearTimeout(closeAnimation);
      };
    } else if (packagesCount !== currentPackageCount.current) {
      currentPackageCount.current = packagesCount;
    }
  }, [packagesCount]);

  return (
    <CartLogoContainer textAlign="center" onClick={toggleOpen} overlayVisible={overlayVisible}>
      <CartLogoImg src={SUMMARY_IMAGES.CART_LOGO} alt="Cart" />
      {packagesCount > 0 ? (
        <CartLogoWithAnimation showAnimation={showAnimation}>
          {showAnimation ? (
            <Box>
              <Animation src={SUMMARY_IMAGES.COUNTER_CONFETTI} name="CounterConfetti" autoplay={true} loop={true} />
            </Box>
          ) : null}

          <CartLogoCounter>
            <Text fontSize={9} fontWeight="bold" lineHeight={14} color="pureWhite.0">
              {packagesCount}
            </Text>
          </CartLogoCounter>
        </CartLogoWithAnimation>
      ) : null}
    </CartLogoContainer>
  );
};

CartLogo.propTypes = propTypes;
CartLogo.defaultProps = defaultProps;

export default CartLogo;
