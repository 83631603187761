// Suite Reducer

import { initializeState } from '../../../utilities/storageUtils';
import suiteActionTypes from './suite.types';

const INITIAL_STATE = {
  selectedSuite: null,
  getAvailabilityLoading: false,
  suiteDetailedAvailability: [],
  errorMessage: '',
  nor1UpgradesDetails: {},
  suiteLcoPackage: {},
  flexTripAllowed: false,
  flexPackage: {},
  //planPackages: [],
  dayType: 0,
  suiteRateMismatched: false,
  onSuiteSelectionError: '', // Variable for tracking purposes, seems we are not using error variables anymore, and reuse/add them again could be risky
  personalizationPackageRules: [],
  personalizationResponses: [],
  responseHasPackages: false,
  suiteDetailsFetchSuccess: false,
  getPlanPackageInfoLoading: false,
  taxesAndFeesDetailAvailability: {}
};

const suiteReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case suiteActionTypes.SELECT_SUITE:
      return {
        ...state,
        selectedSuite: action.selectedSuite,
        suiteStoreErrorMessage: ''
      };
    case suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_INFO:
      return {
        ...state,
        getAvailabilityLoading: action.getAvailabilityLoading,
        suiteRateMismatched: action.suiteRateMismatched
      };
    case suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_ERROR:
      return {
        ...state,
        suiteStoreErrorMessage: action.suiteStoreErrorMessage,
        getAvailabilityLoading: action.getAvailabilityLoading,
        suiteDetailedAvailability: action.suiteDetailedAvailability,
        onSuiteSelectionError: action.suiteStoreErrorMessage
      };
    case suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_SUCCESS:
      return {
        ...state,
        getAvailabilityLoading: false,
        suiteDetailedAvailability: action.suiteDetailedAvailability,
        suiteLcoPackage: action.suiteLcoPackage,
        flexTripAllowed: action.flexTripAllowed ?? false,
        flexPackage: action.flexPackage,
        dayType: action.dayType,
        personalizationPackageRules: action.personalizationPackageRules,
        personalizationResponses: action.personalizationResponses,
        responseHasPackages: action.responseHasPackages,
        suiteDetailsFetchSuccess: action.suiteDetailsFetchSuccess,
        selectedSuite: action.availabilityKey
          ? { ...state.selectedSuite, availabilityKey: action.availabilityKey }
          : state.selectedSuite,
        taxesAndFeesDetailAvailability: action.taxesAndFeesDetailAvailability
      };
    case suiteActionTypes.SET_SUITE_NEW_RATES:
      return {
        ...state,
        suiteDetailedAvailability: action.suiteDetailedAvailability
      };
    case suiteActionTypes.CLEAR_SUITE_SELECTED:
      return {
        ...state,
        selectedSuite: action.selectedSuite,
        suiteRateMismatched: action.suiteRateMismatched,
        suiteDetailedAvailability: action.suiteDetailedAvailability
      };
    case suiteActionTypes.GET_SUITE_NOR1_UPGRADES:
      return {
        ...state,
        nor1UpgradesDetails: action.nor1UpgradesDetails
      };
    case suiteActionTypes.GET_SUITE_DETAILED_AVAILABILITY_RESET:
      return {
        ...state,
        suiteDetailsFetchSuccess: false
      };
    case suiteActionTypes.GET_SUITE_PLAN_PACKAGES_INFO_TYPE:
      return {
        ...state,
        getPlanPackageInfoLoading: action.getPlanPackageInfoLoading
      };
    case suiteActionTypes.SET_SUITE_PLAN_PACKAGES_INFO_SUCCESS:
      return {
        ...state,
        planPackages: action.planPackages,
        getPlanPackageInfoLoading: action.getPlanPackageInfoLoading
      };
    default:
      return state;
  }
};

export default suiteReducer;
