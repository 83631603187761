import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getIsCartActive } from '../../store/componentStores/LodgeConfig/lodgeConfig.selectors';
import { TESTS_DICTIONARY, getTestValueAB } from '../../utilities/ABTesting';
import { PlanSummary } from '../Summary';
import { SUMMARY_CART_VARIATIONS } from '../Summary/components/Suite/utilities/constants';
import theme from '../ui-kit/themes/theme';
import { CartCustomContainer } from './assets/styles';
import { CART_ID_DESKTOP, CART_ID_MOBILE } from './utilities/constants';
// Portal Component
const CartPortal = ({ children }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.xl})`
  });
  const cartContainer = document.getElementById(isMobile ? CART_ID_MOBILE : CART_ID_DESKTOP);
  if (!cartContainer) return null;

  return <>{createPortal(children, cartContainer)}</>;
};

// Main Component
// Rules:
// 1. This component can be connected to the store.
// 2. Will load the summary on cart variant and will be displayed on the cart portal.

const propTypes = {
  location: PropTypes.object,
  isCartActive: PropTypes.bool
};

const defaultProps = {
  location: null,
  isCartActive: false
};

const CartContainer = ({ location, isCartActive }) => {
  useEffect(() => {
    const mobileIcon = document.getElementById(CART_ID_MOBILE);
    if (mobileIcon && isCartActive) {
      document.getElementById(CART_ID_MOBILE).style.width = '24px';
      document.getElementById(CART_ID_MOBILE).style.height = '24px';
    }
  }, [isCartActive]);

  // return null if cart is not active and avoid portal rendering
  if (!isCartActive || !location) return null;

  return (
    <CartPortal>
      <CartCustomContainer>
        <PlanSummary location={location} shouldStartOpened={true} variation={SUMMARY_CART_VARIATIONS.CART} />
      </CartCustomContainer>
    </CartPortal>
  );
};

CartContainer.propTypes = propTypes;
CartContainer.defaultProps = defaultProps;

const mapStateToProps = state => ({
  location: state.router.location,
  isCartActive: getIsCartActive(state.lodgeConfig) && getTestValueAB(TESTS_DICTIONARY.optimizelyCartFlow, false)
});

export default connect(mapStateToProps, null)(CartContainer);
