// Lodge Locations Actions

import lodgeLocationsActionTypes from './lodgeLocations.types';
import LodgeApiClient from '../../../api/clients/LodgeApiClient';

export const getAllLodgesLocation = () => async dispatch => {
  dispatch({ type: lodgeLocationsActionTypes.CLEAR_LODGE_LOCATIONS_LOADING_CONFIG });
  dispatch({ type: lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_LOADING, isLoading: true });

  try {
    const configurationClient = new LodgeApiClient();
    const result = await configurationClient.getLodgesLocations();
    const lodges = result.data.reduce((prev, current) => {
      return {
        ...prev,
        [current.id]: {
          address: current.Address,
          booking_phone: current.PhoneNumber,
          display: current.Name,
          shortDisplayName: current.Name,
          mobile_booking_phone: current.MobileBookingPhone,
          phone: current.PhoneNumber,
          operaCode: current.id,
          url: current.ShortName,
          region: current.Region,
          birthdayEmail: current.BirthdayEmail,
          maxOccupancy: current.MaxOccupancy
        }
      };
    }, {});

    dispatch({
      type: lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_SUCCESS,
      locationList: lodges
    });
    dispatch({ type: lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_LOADING, isLoading: false });
  } catch (error) {
    dispatch({
      type: lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_ERROR,
      locationList: {},
      requestFailed: true,
      errorMessage: `Oops, ${error.message}`
    });
    dispatch({ type: lodgeLocationsActionTypes.GET_LODGE_LOCATIONS_LOADING, isLoading: false });
  }
};
