// Global Modal action types

const globalModalActionTypes = {
  OPEN_GLOBAL_MODAL: 'OPEN_GLOBAL_MODAL',
  CLOSE_GLOBAL_MODAL: 'CLOSE_GLOBAL_MODAL',
  RESET_GLOBAL_MODAL: 'RESET_GLOBAL_MODAL',
  UPDATE_GLOBAL_MODAL_PARAMS: 'UPDATE_GLOBAL_MODAL_PARAMS'
};

export default globalModalActionTypes;
