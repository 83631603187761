import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getUserIsLoggedIn } from '../../../store/componentStores/Account/account.selectors';
import { showLoyalty } from '../../../store/reducers';
import { Box, Flex, Text } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';
import { editCopyByReplaceWithMultipleComponent } from '../../utilities/copyFunctions';
import { Icon, LoyaltyGenericContainer, TextBoldWithNormal } from '../assets/styles';
import { LOYALTY_ICON_LINK } from '../utilities/constants';
import * as COPY from '../utilities/copy';

const LoyaltyAvailableMobileSearch = ({ amount, iconLink, showLoyalty, isUserLoggedIn, isNewWidgetStyleActive }) => {
  const loggedUserCopy = {
    '<amount>': amount
  };

  return showLoyalty && isUserLoggedIn ? (
    <LoyaltyGenericContainer
      isNewWidgetStyleActive={isNewWidgetStyleActive}
      alignItems="center"
      maxWidth="100%"
      enableMobilePadding>
      <Icon src={iconLink} alt="Loyalty" size={isNewWidgetStyleActive ? '32px' : '18px'} />
      <Box ml="12px" maxWidth={isNewWidgetStyleActive ? '100%' : '127px'}>
        <Flex flexDirection="column">
          <TextBoldWithNormal
            color={isNewWidgetStyleActive && theme.colors.deepLakeBlue[100]}
            lineHeight={isNewWidgetStyleActive && '16px!important'}
            fontSize={0}>
            {editCopyByReplaceWithMultipleComponent(COPY.LOYALTY_USER_COPY, loggedUserCopy)}
          </TextBoldWithNormal>
          <Text
            color={isNewWidgetStyleActive && theme.colors.deepLakeBlue[100]}
            lineHeight={isNewWidgetStyleActive && '16px!important'}
            fontSize={0}>
            {COPY.AVAILABLE}
          </Text>
        </Flex>
      </Box>
    </LoyaltyGenericContainer>
  ) : (
    <Text
      fontSize={`${isNewWidgetStyleActive ? theme.fontSizes[2] : theme.fontSizes[15]}px`}
      lineHeight={isNewWidgetStyleActive && '24px'}
      fontWeight={isNewWidgetStyleActive ? theme.fontWeights.medium : theme.fontWeights.bold}
      color={theme.colors.deepLakeBlue[100]}>
      {COPY.USER_COPY}
    </Text>
  );
};

LoyaltyAvailableMobileSearch.propTypes = {
  amount: PropTypes.number,
  iconLink: PropTypes.string
};
LoyaltyAvailableMobileSearch.defaultProps = {
  amount: '0',
  iconLink: LOYALTY_ICON_LINK
};

export default connect(state => {
  return {
    showLoyalty: showLoyalty(state),
    isUserLoggedIn: getUserIsLoggedIn(state.account)
  };
})(LoyaltyAvailableMobileSearch);
