import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Manage Booking endpoints
 *
 * @class ManageBookingApiClient
 * @extends {GWRApiClient}
 */
export default class ManageBookingApiClient extends GWRApiClient {
  constructor(version = '', token = '', tiogasAPI = false) {
    let apiVersion = version !== '' ? version : `v1`;
    const urlAux = getApiUrl(API_URLS.REACT_APP_MANAGE_BOOKING_ENDPOINT, apiVersion, tiogasAPI);
    super(urlAux, token);
  }

  /**
   * function returns the preview cancellation information
   *
   * @function
   * @param { reservationId: string}
   */
  async previewCancel(reservationId) {
    const urlRequest = `${this.url}/reservation/${reservationId}/cancellation`;
    return await super.getApiCall(urlRequest);
  }

  /**
   * function returns the add guest modal information
   *
   * @function
   * @param { reservationId: string, requestObj: {kidsAges: [number]}}
   */
  async getAddGuestsModalInformation(reservationId, requestObj) {
    let kidsAgesString = '';
    requestObj?.kidsAges &&
      requestObj?.kidsAges.forEach(kid => {
        kidsAgesString += '&kidsAges=' + kid;
      });
    const urlRequest =
      `${this.url}/reservation/${reservationId}/modify-guests?numOfAdults=` + requestObj.adults + kidsAgesString;
    return await super.getApiCall(urlRequest);
  }

  /*
   * function returns the information for the reservation
   *
   * @function
   * @param { reservationId: string }
   */
  async manageBooking(reservationId) {
    return await super.getApiCall(`${this.url}/reservations/${reservationId}`);
  }

  /*
   * function returns the add guest modal information after saving the new guests
   *
   * @function
   * @param { reservationId: string, requestObj: {numberOfAdults: number, kidsAges: [number]}}
   */
  async updateAddGuestsModal(reservationId, requestObj) {
    const urlRequest = `${this.url}/reservation/${reservationId}/modify-guests`;
    return await super.postApiCall(urlRequest, requestObj);
  }
}
