// Global Modal actions

import { isDevEnvironment } from '../../../utilities/env';
import globalModalActionTypes from './globalModal.types';
import { verifyIfModalIsOpened } from './globalModal.selectors';

const logConsoleMessage = (type, message, objects = []) => {
  if (isDevEnvironment) {
    console.log(`[${type}] ${message}`);
    if (objects && objects.length > 0) {
      objects.forEach(property => {
        console.log({ property });
      });
    }
  }
};

export const openModal = ({ keyContentComponent, params }, shouldUseGWComponent = false, shouldForceOpen = false) => (
  dispatch,
  getState
) => {
  const currentState = getState();
  const { globalModal } = currentState;

  logConsoleMessage('REQUEST', `Open modal with key: ${keyContentComponent} - [FORCE]: ${shouldForceOpen}`);

  if (!globalModal.isVisible || !globalModal.keyContentComponent || shouldForceOpen) {
    logConsoleMessage('SUCCESS', `Modal opened with success`);

    dispatch({
      type: globalModalActionTypes.OPEN_GLOBAL_MODAL,
      payload: {
        keyContentComponent,
        shouldUseGWComponent,
        params
      }
    });
  } else {
    logConsoleMessage('FAILURE', `It was not possible to open the modal`, [keyContentComponent, globalModal]);
  }
};
export const closeModal = key => (dispatch, getState) => {
  const currentState = getState();
  const { globalModal } = currentState;

  logConsoleMessage('REQUEST', `Close modal with key: ${key}`);

  if (!key || key === globalModal.keyContentComponent) {
    logConsoleMessage('SUCCESS', `Modal closed with success`);

    dispatch({
      type: globalModalActionTypes.CLOSE_GLOBAL_MODAL
    });
  } else {
    logConsoleMessage('FAILURE', `It was not possible to close the modal.`, [key, globalModal]);
  }
};
export const updateParams = params => (dispatch, getState) => {
  dispatch({
    type: globalModalActionTypes.UPDATE_GLOBAL_MODAL_PARAMS,
    payload: {
      params
    }
  });
};
export const resetModal = () => dispatch => {
  dispatch({
    type: globalModalActionTypes.RESET_GLOBAL_MODAL
  });
};

export const openOrUpdateModal = ({ keyContentComponent, params }, shouldUseGWComponent = false) => (
  dispatch,
  getState
) => {
  const currentState = getState();
  const isOpened = verifyIfModalIsOpened(keyContentComponent, currentState);

  if (isOpened) {
    dispatch(updateParams(params));
  } else {
    dispatch(openModal({ keyContentComponent, params }, shouldUseGWComponent));
  }
};

export const toggleModal = ({ keyContentComponent, params }, shouldUseGWComponent = false) => (dispatch, getState) => {
  const currentState = getState();
  const isOpened = verifyIfModalIsOpened(keyContentComponent, currentState);

  if (isOpened) {
    dispatch(closeModal(keyContentComponent));
  } else {
    dispatch(openModal({ keyContentComponent, params }, shouldUseGWComponent));
  }
};
