// Event Calendar reducer

import { combineReducers } from 'redux';
import eventCalendarActionTypes from './eventCalendar.types';

const data = (state = {}, action) => {
  switch (action.type) {
    case eventCalendarActionTypes.GET_EVENT_CALENDAR_SUCCESS:
      return action.response;
    case eventCalendarActionTypes.GET_EVENT_CALENDAR_ERROR:
      return {};
    default:
      return state;
  }
};

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case eventCalendarActionTypes.GET_EVENT_CALENDAR_ERROR:
      return action.message;
    case eventCalendarActionTypes.GET_EVENT_CALENDAR_SUCCESS:
      return null;
    default:
      return state;
  }
};

const requestSuccess = (state = false, action) => {
  switch (action.type) {
    case eventCalendarActionTypes.GET_EVENT_CALENDAR_SUCCESS:
      return true;
    case eventCalendarActionTypes.GET_EVENT_CALENDAR_ERROR:
      return false;
    default:
      return state;
  }
};

const eventCalendarReducer = combineReducers({
  requestSuccess,
  data,
  errorMessage
});

export default eventCalendarReducer;
