import { connect } from 'react-redux';
import { Summary } from './Summary';

import * as addAPackageActionCreators from '../../../../../store/componentStores/addAPackage/addAPackage.actions';
import {
  getAddGuestReservationObj,
  getAdults,
  getArrivalDateObject,
  getBillableGuest,
  getCabanaDeposit,
  getDepartureDateObject,
  getDepositePaid,
  getKids,
  getNumberOfNights,
  getOffersDiscountApplied,
  getParkingFee,
  getPointsDiscountApplied,
  getRateType,
  getResortFee,
  getRoomDeposit,
  getSuiteDetails,
  getSuppressRate,
  getSustainabilityFee,
  getTotalBillAndNonBillGuest,
  getTotalRoomRate
} from '../../../../../store/componentStores/ReservationDetailsEntity/reservationDetailsEntity.selectors';
import {
  getAditionalPackagesFromAddApackage,
  getDueAtCheckinAmountWithAdditionalPackages,
  getPackagesWithAmount,
  getSummaryTotalWithAdditionalPackages,
  getTaxesAmountWithAdditionalPackages,
  packagesWithAmountFromReservationData
} from '../../../../../store/reducers';

import { CONNECTICUT } from '../../../../../utilities/constants';
import { getDateMonthDayFormat } from '../../../../../utilities/dates';
import { getGuestCountLabel } from '../../../../../utilities/guestsUtils';
import { getTaxesAndFees } from '../../../../../utilities/taxes';

const getPackage = pkg => {
  return {
    packageName: pkg.packageTitle,
    packageCode: pkg.packageCode,
    amount: pkg.packageAmount != null ? Number.parseFloat(pkg.packageAmount) : 0,
    quantity: pkg.quantity,
    packageType: pkg.packageType,
    packageCharge: pkg.packageCharge,
    packageAllowance: pkg.packageAllowance
  };
};

const mapStateToProps = state => {
  const packages =
    CONNECTICUT === state.currentLodge.resortLocation
      ? packagesWithAmountFromReservationData(state)
      : getPackagesWithAmount(state);

  const hasPackages = packages != null && packages.length > 0;
  const selectedPackages = hasPackages
    ? packages.filter(pkg => !pkg.isAdjustment && pkg.packageAmount != null).map(pkg => getPackage(pkg))
    : [];

  const { offerCode } = getSuiteDetails(state.entities.reservationDetails);
  const taxesAndFees = getTaxesAndFees(
    getSustainabilityFee(state.entities.reservationDetails),
    getTaxesAmountWithAdditionalPackages(state),
    getResortFee(state.entities.reservationDetails),
    getParkingFee(state.entities.reservationDetails)
  );

  const addGuestReservation = getAddGuestReservationObj(state.entities.reservationDetails);

  const voyagerPointsDiscount = getPointsDiscountApplied(state.entities.reservationDetails);
  const offersPointsDiscount = getOffersDiscountApplied(state.entities.reservationDetails);
  const hasDiscountCharges = voyagerPointsDiscount || offersPointsDiscount ? true : false;

  return {
    additionalPackages: getAditionalPackagesFromAddApackage(state, true),
    checkIn: getDateMonthDayFormat(getArrivalDateObject(state.entities.reservationDetails)),
    checkOut: getDateMonthDayFormat(getDepartureDateObject(state.entities.reservationDetails)),
    depositPaid: getDepositePaid(state.entities.reservationDetails),
    dueAtCheckinAmount: getDueAtCheckinAmountWithAdditionalPackages(state),
    guestCountText: getGuestCountLabel(
      addGuestReservation ? addGuestReservation?.numberOfAdults : getAdults(state.entities.reservationDetails),
      addGuestReservation ? addGuestReservation?.numberOfChildren : getKids(state.entities.reservationDetails),
      null,
      true
    ),
    addGuestReservation: addGuestReservation,
    hasAdditionalCharges: taxesAndFees && taxesAndFees.length > 0,
    hasSelectedPackages: selectedPackages && selectedPackages.length > 0,
    numberOfGuests: getBillableGuest(state.entities.reservationDetails),
    numberOfNights: getNumberOfNights(state.entities.reservationDetails),
    packages: selectedPackages,
    promoCode: offerCode,
    resortLocation: state.currentLodge,
    suiteTotal: getTotalRoomRate(state.entities.reservationDetails),
    taxesAndFees: taxesAndFees,
    total: getSummaryTotalWithAdditionalPackages(state),
    suppressRate: getSuppressRate(state.entities.reservationDetails),
    cabanaDeposit: getCabanaDeposit(state.entities.reservationDetails),
    roomDeposit: getRoomDeposit(state.entities.reservationDetails),
    hasDiscountCharges,
    voyagerPointsDiscount,
    offersPointsDiscount,
    totalBillAndNonBillGuest: getTotalBillAndNonBillGuest(state.entities.reservationDetails),
    rateType: getRateType(state.entities.reservationDetails)
  };
};

const ReservationSummary = connect(mapStateToProps, addAPackageActionCreators)(Summary);

export { ReservationSummary };
