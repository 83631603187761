// Lodge Locations action types

const lodgeLocationsActionTypes = {
  GET_LODGE_LOCATIONS_SUCCESS: 'GET_LODGE_LOCATIONS_SUCCESS',
  GET_LODGE_LOCATIONS_LOADING: 'GET_LODGE_LOCATIONS_LOADING',
  GET_LODGE_LOCATIONS_ERROR: 'GET_LODGE_LOCATIONS_ERROR',
  CLEAR_LODGE_LOCATIONS_LOADING_CONFIG: 'CLEAR_LODGE_LOCATIONS_LOADING_CONFIG'
};

export default lodgeLocationsActionTypes;
