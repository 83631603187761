// Dates Selectors

import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';

/**
 * Get number of nights based on check in and check out date selected.
 * @param {Object} reservation dates
 * @return {number} total number of nights
 */
export const getTotalNumberOfNights = state => {
  const { checkinDateSelection, checkoutDateSelection } = state;
  if (!checkinDateSelection || !checkoutDateSelection) return 0;
  return GwDatesWrapper.diff(checkoutDateSelection, checkinDateSelection);
};

/**
 * Get selected check in date.
 * @param {Object} state
 * @returns {string} String date.
 */
export const getCheckIn = state => state.checkinDateSelection;

/**
 * Get selected check out date.
 * @param {Object} state
 * @returns {string} String date.
 */
export const getCheckOut = state => state.checkoutDateSelection;
