// Current Lodge Selectors

export const getResortLocation = state => {
  return state.resortLocation;
};

export const getResortLocationUrlParam = state => {
  return state.resortLocationUrl;
};
export const getCurrentResort = state => {
  return state.resortData;
};

export const getCurrentResortPhone = state => {
  return state.resortData.phone;
};

export const getCurrentResortDisplay = state => {
  return state.resortData.display;
};

export const getCurrentResortDataUrl = state => {
  return state.resortData.url;
};
