// LeadGen reducer

import leadGenActionTypes from './leadGen.types';

const INITIAL_STATE = {
  isProcessing: false,
  isSignupSuccess: false,
  isSignupFailure: false,
  isAlreadyShown: false
};

const leadGenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case leadGenActionTypes.IS_LEADGEN_PROCESSING_TYPE:
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSignupSuccess: action.isSignupSuccess,
        isSignupFailure: action.isSignupFailure,
        isAlreadyShown: action.isAlreadyShown
      };
    case leadGenActionTypes.SET_LEADGEN_IS_ALREADY_SHOWN:
      return {
        ...state,
        isAlreadyShown: action.isAlreadyShown
      };
    case leadGenActionTypes.RESET_LEADGEN_STATUS:
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSignupSuccess: action.isSignupSuccess,
        isSignupFailure: action.isSignupFailure
      };
    default:
      return state;
  }
};

export default leadGenReducer;
