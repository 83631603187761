// IEModal reducer

import ieModalActionTypes from './ieModal.types';

const INITIAL_STATE = {
  isAlreadyShown: false
};

const ieModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ieModalActionTypes.SET_IEMODAL_IS_ALREADY_SHOWN:
      return {
        ...state,
        isAlreadyShown: action.isAlreadyShown
      };
    default:
      return state;
  }
};

export default ieModalReducer;
