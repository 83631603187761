// Reservation Details Actions

import reservationDetailsActionTypes from './reservationDetailsEntity.types';
import ReservationsApiClient from '../../../api/clients/ReservationsApiClient';
import { getErrorMessage } from '../../../utilities/messageUtils';
import ManageBookingApiClient from '../../../api/clients/ManageBookingApiClient';
import { getAdjustmentCodesByPackageCode } from '../../reducers';
import addAPackageActionTypes from '../addAPackage/addAPackage.types';
import { push } from 'connected-react-router';
import { reverse } from 'named-urls';
import routes from '../../../routes';
import { fillVaultedCardDetails } from '../../../components/PaymentForm/PaymentFormHelper';
import { sendAddExtraGuestInfo } from '../../../infrastructure/middleware/analytics/analyticsUtils';
import { EVENT_CATEGORIES } from '../../../utilities/constants';
import { getKidsAges } from '../Guests/guests.selectors';

export const getReservationDetails = reservationId => async dispatch => {
  dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });

  try {
    const apiClient = new ReservationsApiClient();
    let response = await apiClient.getReservationsById(reservationId);

    delete response.data['checkIn'];
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS,
      response: response.data
    });
  } catch (err) {
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
      message: getErrorMessage(err.message)
    });
  }
};
export const getReservationDetailsByReservationIdAndLastName = (
  reservationId,
  lastName,
  avoidLoading = false
) => async dispatch => {
  try {
    if (!avoidLoading) dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });
    const apiClient = new ReservationsApiClient();
    const response = await apiClient.getReservationsByIdAndLastName(reservationId, lastName);
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS,
      response: response.data
    });
  } catch (error) {
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
      message: getErrorMessage(error.message)
    });
  }
};
export const getReservationDetailsByReservationIdAndReservationKey = (
  reservationId,
  reservationkey
) => async dispatch => {
  try {
    dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });

    const apiClient = new ReservationsApiClient('v2.1');
    const response = await apiClient.getReservationsByIdAndReservationKey(reservationId, reservationkey);

    dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS, response: response.data });
  } catch (error) {
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
      message: getErrorMessage(error.message)
    });
  }
};
export const updateAddGuestsReservation = (reservationId, requestObj) => dispatch => {
  const apiClient = new ManageBookingApiClient();
  const request = apiClient.updateAddGuestsModal(reservationId, requestObj);

  return request.then(
    response => {
      dispatch({
        type: reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_SUCCESS,
        addGuestsReservationObj: response.data
      });
    },
    err => {
      dispatch({
        type: reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_ERROR,
        message: getErrorMessage(err.message)
      });
    }
  );
};
export const clearAddGuestsReservation = () => dispatch => {
  dispatch({
    type: reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_NEW_GUESTS_RESERVATION_CLEAR
  });
};
export const updateReservationCheckIn = reservationId => async dispatch => {
  dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });

  try {
    const apiClient = new ReservationsApiClient('v3');
    const response = await apiClient.getReservationCheckIn(reservationId);

    dispatch({
      type: reservationDetailsActionTypes.UPDATE_RESERVATION_DETAILS_CHECK_IN_VALUE_SUCCESS,
      response: response.data
    });
  } catch (err) {
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
      message: getErrorMessage(err.message)
    });
  }
};
export const updateSpecialRequest = (
  reservationId,
  specialRequest,
  celebration,
  customizedSpecialRequest
) => dispatch => {
  dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });
  const apiClient = new ReservationsApiClient();
  const request = apiClient.modifyReservation({
    reservationId,
    specialRequests: specialRequest,
    alerts: celebration,
    comments: customizedSpecialRequest
  });

  return request
    .then(response => {
      if (!response.data) {
        throw new Error();
      }
      dispatch({
        type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS,
        response: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
        message: getErrorMessage(error.message)
      });
    });
};
export const updateReservationPackages = (
  reservationId,
  packages,
  maxOccupancy,
  updateLCO,
  customPurchaseLocation = null
) => async (dispatch, getState) => {
  dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });
  const currentState = getState();

  const packagesWithAdjustmentPackages = packages?.flatMap(pkg => {
    const adjustmentCodes = getAdjustmentCodesByPackageCode(currentState, pkg.packageCode);

    const kidsAges = getKidsAges(currentState?.entities.reservationDetails);
    const numberOfUnbillableKids = kidsAges?.filter(age => age <= 3)?.length;

    const regularPackage = {
      isAdjustment: false,
      packageCode: pkg.packageCode,
      quantity: pkg.quantity,
      title: pkg.title,
      packageType: pkg.packageType
    };

    if (numberOfUnbillableKids > 0 && adjustmentCodes && adjustmentCodes?.length > 0) {
      const newAdjustmentPackages = adjustmentCodes?.map(adjustmentCode => {
        return {
          isAdjustment: true,
          packageCode: adjustmentCode,
          quantity: pkg.quantity && parseInt(pkg.quantity),
          title: 'Adjustment Package',
          packageType: pkg.packageType
        };
      });

      const bothRegularAndAdjustmentPackages = [...newAdjustmentPackages, regularPackage];
      return bothRegularAndAdjustmentPackages;
    }

    return regularPackage;
  });

  packages?.forEach(pkg => {
    if (pkg.packageSelectedQuantity != null) {
      packagesWithAdjustmentPackages.filter(
        packageWithAdjustment => packageWithAdjustment.packageCode === pkg.packageCode
      )[0].quantityPerson = pkg.packageSelectedQuantity;
    }
  });

  const apiClient = new ReservationsApiClient();

  const requestResponse = { status: 'failed', data: {} };

  try {
    const request = await apiClient.modifyReservation({
      reservationId,
      packages: packagesWithAdjustmentPackages
    });

    const addedPackages = packages.map(({ quantity, title }) => {
      return `${quantity}x ${title}`;
    });

    if (!request.data) {
      throw new Error();
    }

    dispatch({
      type: reservationDetailsActionTypes.GTM_POST_RESERVATION_DETAILS_SALE_PACKAGE_ADDED,
      response: {
        reservation: request.data,
        packages: [...packagesWithAdjustmentPackages],
        maxOccupancy,
        customPurchaseLocation
      }
    });
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS,
      response: request.data
    });
    dispatch({
      type: addAPackageActionTypes.SET_ADD_A_PACKAGE_FLASH_MESSAGE,
      message: `${addedPackages.join(', ')} has been added to your stay.`
    });
    const isNewReservation = currentState.router?.location?.newReservation ?? false;
    if (!updateLCO) {
      dispatch(
        push(
          `${reverse(routes.reservations.myReservations.detail.self, {
            reservationId: reservationId,
            lastName: request.data.lastName
          })}${!isNewReservation ? `?pck=${packages[0].packageType}` : ''}`
        )
      );
    }
    requestResponse.status = 'success';
    requestResponse.data = request.data;
  } catch (error) {
    const requestResponse = error?.err?.response?.data ?? null;

    const errorMessage = getErrorMessage(error.message);

    dispatch({
      type: reservationDetailsActionTypes.PACKAGE_RESERVATION_DETAILS_MESSAGE_ERROR,
      message: requestResponse ?? errorMessage
    });
    dispatch({
      type: 'CLOSE_MODAL'
    });
  }

  dispatch({
    type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES
  });

  return requestResponse;
};
export const clearReservationDetails = () => ({
  type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_CLEAR
});
export const clearCabanaDetails = () => ({
  type: reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_CABANA_DETAILS
});
export const addCabanasToReservation = (reservationId, cabanas, items) => async dispatch => {
  try {
    dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });
    const apiClient = new ReservationsApiClient();
    const request = await apiClient.addCabana(cabanas);
    const addedPackages = items.map(({ quantityAdded, title }) => {
      return `${quantityAdded}x ${title}`;
    });

    if (!request.data) {
      throw new Error();
    }

    dispatch({
      type: reservationDetailsActionTypes.GTM_POST_RESERVATION_DETAILS_SALE_PACKAGE_ADDED,
      response: { cabanas: request.data }
    });
    dispatch({
      type: addAPackageActionTypes.SET_ADD_A_PACKAGE_FLASH_MESSAGE,
      message: `${addedPackages.join(', ')} has been added to your stay.`
    });
    dispatch(
      push(
        reverse(routes.reservations.myReservations.detail.self, {
          reservationId: reservationId
        })
      )
    );
    dispatch({ type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES });
  } catch (error) {
    dispatch({
      type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
      message: getErrorMessage(error.message)
    });
    dispatch({ type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES });
  }
};
export const addCabanasToReservationV3 = (
  reservationId,
  cabanas,
  title,
  lastName,
  resortLocation,
  isDeposit = false
) => async dispatch => {
  try {
    // LOADING COMPONENT BASED ON FLOW
    if (!isDeposit) {
      dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });
    } else {
      dispatch({ type: reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_IS_LOADING });
      const tokenizedPayload = await fillVaultedCardDetails(
        cabanas.payment.creditcard,
        cabanas.guest.address,
        resortLocation
      );
      Object.assign(cabanas.payment.creditcard, tokenizedPayload);
    }

    const apiClient = new ReservationsApiClient('v2.9');
    const request = await apiClient.addCabanaV3(reservationId, cabanas, lastName);
    const addedPackages = `${cabanas.dates.length}x ${title}`;
    if (!request.data) {
      throw new Error();
    }

    dispatch({
      type: reservationDetailsActionTypes.GTM_POST_RESERVATION_DETAILS_SALE_PACKAGE_ADDED,
      response: { cabanas: request.data }
    });

    // REGULAR FLOW ON NO DEPOSIT CABANA
    if (!isDeposit) {
      dispatch({
        type: addAPackageActionTypes.SET_ADD_A_PACKAGE_FLASH_MESSAGE,
        message: `${addedPackages} has been added to your stay.`
      });
      dispatch(
        push(
          reverse(routes.reservations.myReservations.detail.self, {
            reservationId: reservationId
          })
        )
      );
    }
    // CLEAN PKGs ON REDUX
    dispatch({ type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES });
    // CLEAR LOADING ON DEPOSIT
    if (isDeposit) dispatch({ type: reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_SUCCESS });
    // SHOW CONF MODAL ON NO DEPOSIT FLOW
    if (!isDeposit) return true;
  } catch (error) {
    if (!isDeposit) {
      console.log(error);
      dispatch({
        type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
        message: getErrorMessage(error.message)
      });
      dispatch({ type: addAPackageActionTypes.CLEAR_ADD_A_PACKAGE_ALL_ADDED_PACKAGES });
      dispatch({ type: 'CLOSE_MODAL' });
      //DO NOT SHOW CONF MODAL ON NO DEPOSIT FLOW
      return false;
    } else {
      dispatch({
        type: reservationDetailsActionTypes.RESERVATION_DETAILS_CABANA_FAILURE,
        message: getErrorMessage(error.message)
      });
    }
  }
};
export const clearPackagesError = () => dispatch => {
  dispatch({
    type: reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_DETAILS_ERROR
  });
};
export const updateGuestsCountRequest = (
  reservationId,
  additionalGuests,
  onGuestRequestSuccess,
  extraAmount,
  suiteContent
) => dispatch => {
  dispatch({ type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_REQUEST });
  const apiClient = new ReservationsApiClient();
  const request = apiClient.modifyReservation({
    reservationId,
    additionalGuests
  });

  return request
    .then(response => {
      if (!response.data) {
        throw new Error();
      }
      dispatch({
        type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_SUCCESS,
        response: response.data
      });
      sendAddExtraGuestInfo(EVENT_CATEGORIES.GTM.transaction, extraAmount, additionalGuests, suiteContent);
      onGuestRequestSuccess && onGuestRequestSuccess();
    })
    .catch(error => {
      dispatch({
        type: reservationDetailsActionTypes.FETCH_RESERVATION_DETAILS_FAILURE,
        message: getErrorMessage(error.message)
      });
      const { maxOccupancy, suiteCategory } = suiteContent;
      sendAddExtraGuestInfo(EVENT_CATEGORIES.GTM.modifyError, 0, additionalGuests, maxOccupancy, suiteCategory);
    });
};
export const clearPackageErrorMessage = () => dispatch => {
  dispatch({
    type: reservationDetailsActionTypes.CLEAR_RESERVATION_DETAILS_PACKAGE_MESSAGE_ERROR
  });
};
