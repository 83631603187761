import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getAvailableOffersBySearch } from '../../../store/componentStores/Offer/offer.selectors';
import { showLoyalty } from '../../../store/reducers';
import { Box, Text, TextLink } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';
import * as COPY_FUNCTIONS from '../../utilities/copyFunctions';
import { Icon, LoyaltyAvailableContainer } from '../assets/styles';
import { LOYALTY_ICON_LINK } from '../utilities/constants';
import * as COPY from '../utilities/copy';

const LoyaltyAvailable = ({
  isNewWidgetStyleActive,
  adventureBucks,
  iconLink,
  iconSize,
  showLoyalty,
  getAvailableOffersBySearch,
  loyaltyEarnThresholdNights = 2,
  numberOfNights = 0
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.xl})`
  });

  const bonusDealLink = (
    <TextLink
      color={isNewWidgetStyleActive ? 'voyagersGreen' : 'springGreen.100'}
      onClick={() => {
        document.querySelector('[data-eventid="voyagers-club-offers"]').click();
      }}>
      {getAvailableOffersBySearch.length === 1 ? COPY.YOU_HAVE_A_BONUS_DEAL : COPY.YOU_HAVE_BONUS_DEALS}
    </TextLink>
  );

  const boldText = (
    <Text as="b">
      {COPY_FUNCTIONS.editCopyByReplaceString(
        getAvailableOffersBySearch.length > 0 ? COPY.VOYAGERS_POINTS_NO_EXCLAMATION : COPY.VOYAGERS_POINTS_EXCLAMATION,
        adventureBucks,
        '<POINTS>'
      )}
    </Text>
  );

  const noPointsCopy = {
    '<TEXT>': boldText,
    '<SECOND_TEXT>':
      getAvailableOffersBySearch.length > 0
        ? bonusDealLink
        : earnCopySelection(loyaltyEarnThresholdNights, numberOfNights)
  };

  const nwithPointsCopy = {
    '<TEXT>': boldText,
    '<SECOND_TEXT>': getAvailableOffersBySearch.length > 0 ? bonusDealLink : COPY.SELECT_SUITE
  };

  const textForMobileTest = {
    '<TEXT>': boldText,
    '<SECOND_TEXT>': bonusDealLink
  };

  const copyToUse = getAvailableOffersBySearch.length > 0 ? COPY.YOU_HAVE_POINTS_BONUS_LINK : COPY.YOU_HAVE;

  const LoyaltyContainer = (
    <LoyaltyAvailableContainer isNewWidgetStyleActive={isNewWidgetStyleActive} isMobile={isMobile}>
      <Icon
        alignSelf={isNewWidgetStyleActive && 'flex-start'}
        src={iconLink}
        alt="Loyalty"
        size={isNewWidgetStyleActive ? (isMobile ? '32px' : '24px') : iconSize}
      />
      <Box ml={isNewWidgetStyleActive ? 2 : isMobile ? 3 : 2}>
        <Text
          as="p"
          my="0"
          fontSize={isMobile ? 1 : 0}
          fontWeight={theme.fontWeights.medium}
          color={!isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]}>
          {isMobile ? (
            <>
              <Text
                fontWeight={theme.fontWeights.bold}
                fontSize={isNewWidgetStyleActive ? 0 : 1}
                color={!isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]}
                lineHeight={!isNewWidgetStyleActive ? 20 : 16}>
                {getAvailableOffersBySearch.length > 0
                  ? COPY_FUNCTIONS.editCopyByReplaceWithMultipleComponent(copyToUse, textForMobileTest)
                  : COPY_FUNCTIONS.editCopyByReplaceString(COPY.YOU_HAVE_POINTS, adventureBucks, '<POINTS>')}
              </Text>
              {adventureBucks === 0 ? (
                <Text
                  mt={isNewWidgetStyleActive ? 0 : 1}
                  fontSize={isNewWidgetStyleActive ? 0 : 9}
                  color={!isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]}
                  fontWeight={isNewWidgetStyleActive ? theme.fontWeights.medium : theme.fontWeights.regular}
                  lineHeight={!isNewWidgetStyleActive ? 20 : 16}>
                  {earnCopySelection(loyaltyEarnThresholdNights, numberOfNights)}
                </Text>
              ) : (
                <Text
                  mt="1"
                  fontSize={isNewWidgetStyleActive ? 0 : 9}
                  color={!isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]}
                  fontWeight={theme.fontWeights.regular}
                  lineHeight={!isNewWidgetStyleActive ? 14 : 16}>
                  {COPY.SELECT_SUITE}
                </Text>
              )}
            </>
          ) : (
            <>
              {adventureBucks === 0 ? (
                <Text
                  fontSize={!isNewWidgetStyleActive ? 1 : 2}
                  color={!isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]}
                  lineHeight={!isNewWidgetStyleActive ? 20 : 16}>
                  {COPY_FUNCTIONS.editCopyByReplaceWithMultipleComponent(copyToUse, noPointsCopy)}
                </Text>
              ) : (
                <Text
                  fontSize={!isNewWidgetStyleActive ? 1 : 2}
                  color={!isNewWidgetStyleActive ? theme.colors.pureWhite[0] : theme.colors.deepLakeBlue[100]}
                  lineHeight={!isNewWidgetStyleActive ? 20 : 16}>
                  {COPY_FUNCTIONS.editCopyByReplaceWithMultipleComponent(copyToUse, nwithPointsCopy)}
                </Text>
              )}
            </>
          )}
        </Text>
      </Box>
    </LoyaltyAvailableContainer>
  );

  return <>{showLoyalty ? LoyaltyContainer : null}</>;
};
LoyaltyAvailable.propTypes = {
  adventureBucks: PropTypes.number,
  iconLink: PropTypes.string,
  iconSize: PropTypes.string
};
LoyaltyAvailable.defaultProps = {
  adventureBucks: '153',
  iconLink: LOYALTY_ICON_LINK
};

export default connect(state => {
  return {
    showLoyalty: showLoyalty(state),
    getAvailableOffersBySearch: getAvailableOffersBySearch(state)
  };
})(LoyaltyAvailable);

function earnCopySelection(loyaltyEarnThresholdNights = 2, numberOfNights) {
  if (numberOfNights < loyaltyEarnThresholdNights) {
    return COPY_FUNCTIONS.editCopyByReplaceString(COPY.EARN_POINTS, loyaltyEarnThresholdNights, '<THRESHOLD_NIGHTS>');
  }
  return COPY.START_EARNING;
}
